import cn from 'classnames';
import style from './Subscribe.styl';
import { Title, Promocode, TariffsList } from '@components';
import { Section, CurrentTariff } from '../../components';
import { appConfig } from '@utils/config';
import * as images from './images';
import { dateFormat } from '@utils/helpers';

export function Subscribe(props) {
  const {
    currentTariff,
    lastPayment,
    tariffsList,
    subscription,
    promocode,
    promocodeResult,
    handlePromocodeChange,
    handlePromocodeApply,
    handlePromocodeClean
  } = props;

  const forMobileApp = (currentTariff?.apple_pay_id !== '' || currentTariff?.google_pay_id !== '');

  return (
    <Section id="subscribe">
      <Section.Head>
        <Title tag="h2" size="small">Подписка</Title>
      </Section.Head>
      <Section.Content>

        {
          (!currentTariff && lastPayment)
          && <div className={style.archiveTariff}>
            Тариф <b>{lastPayment.tariff.name}</b>, на который вы подписаны, перемещен в архив.
            <br />
            Подписка {appConfig.subscribeActive.includes(subscription.status) ? 'закончится' : 'закончилась'}{' '}
            <b>{dateFormat(subscription.due_date)}</b>, чтобы продлить ее выберите тариф из списка.
          </div>
        }

        {
          (currentTariff && forMobileApp)
          && <div className={style.archiveTariff}>
            Тариф <b>{currentTariff.title}</b>, на который вы подписаны, подключен в мобильном приложении.
            <br />
            Подписка {appConfig.subscribeActive.includes(subscription.status) ? 'закончится' : 'закончилась'}{' '}
            <b>{dateFormat(subscription.due_date)}</b>, чтобы продлить ее выберите тариф из списка или откройте приложение.
          </div>
        }

        <div className={style.subscription}>

          {
            (currentTariff && !forMobileApp)
            && <div className={style.currentTariff}>
              <h2 className={style.title}>Ваш тариф</h2>
              <CurrentTariff tariff={currentTariff} subscription={subscription} />
            </div>
          }

          <div className={cn(style.tariffs, { [style.otherTariffs]: (currentTariff && !forMobileApp) })}>
            { (currentTariff && !forMobileApp) && <h2 className={style.title}>Другие тарифы</h2> }
            { tariffsList && <TariffsList tariffs={tariffsList} promocode={promocodeResult} subscription={subscription} /> }
          </div>
        </div>
        <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" className={style.logo}/>
      {/*  <div className={style.recurrent}>
          <Checkbox checked={recurrent} handleChange={handleRecurrentChange}>
            Автоматическое продление подписки
          </Checkbox>
        </div>*/}
        <Promocode
          promocode={promocode}
          promocodeResult={promocodeResult}
          handleChange={handlePromocodeChange}
          handleApply={handlePromocodeApply}
          handleClean={handlePromocodeClean}
        />
      </Section.Content>
    </Section>
  );
}
