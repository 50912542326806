import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Payment } from '../../components';
import {
  fetchPaymentData,
  selectData,
  cleanData,
  selectRecurrentStatus,
  selectPromocode,
  selectPromocodeResult,
  cleanPromocode,
} from '@store/paymentSlice';
import { selectors } from '@store/tariffsSlice';
import { fetchUser } from '@store/usersSlice';
import { arrangePromocodeResult } from '@utils/helpers';

export function PaymentContainer() {
  const { tariffId } = useParams();
  const tariff = useSelector((state) => selectors.selectById(state, tariffId));
  const paymentData = useSelector(selectData);
  const recurrentStatus = useSelector(selectRecurrentStatus);
  const promocode = useSelector(selectPromocode);
  const promocodeResult = useSelector(selectPromocodeResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function closePopup() {
    dispatch(cleanData());
    navigate(-1);
  }

  // Получаем данные для оплаты
  useEffect(() => {
    const tariffsSales = arrangePromocodeResult(promocodeResult);
    const tariffSale = tariffsSales[tariffId];

    dispatch(fetchPaymentData({
      tariffId: Number(tariffId),
      recurrent: (tariff?.is_recurrent && recurrentStatus && (!tariffSale || tariffSale?.is_recurrent)) ? '1' : '0',
      promocode: tariffSale ? promocode : null
    }));
  }, [dispatch, promocode, promocodeResult, recurrentStatus, tariff?.is_recurrent, tariffId]);

  // Обновляем информацию о пользователе (о его подписке)
  // и очищаем поле промокода, если в ответ пришел url
  useEffect(() => {
    if (paymentData?.url) {
      dispatch(fetchUser());
      dispatch(cleanPromocode());
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paymentData]);

  return (
    <>
      {
        paymentData?.confirmation_token
        && <Payment
          token={paymentData.confirmation_token}
          backUrl="https://chevostik.ru/profile"
          handleClose={closePopup}
        />
      }
    </>
  );
}
