import { Outlet } from 'react-router-dom';
import { HeaderContainer, PopupsContainer, SignOutContainer } from '@containers';

export function LayoutHome({setFeedback}) {
  return (
    <>
      <HeaderContainer setFeedback={setFeedback}/>
      <Outlet />
      <PopupsContainer />
      <SignOutContainer />
    </>
  );
}
