import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@components';
import { signIn } from '@store/usersSlice';
import { isEmail } from '@utils/helpers';

export function AuthContainer({ open }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function closePopup() {
    navigate("/?popup=false");
  }

  // Получаем данные из формы
  function setFieldsValues(field, value) {
    switch(field) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  }

  // Отправляем данные на сервер
  async function submit() {
    // Проверяем поля на ошибки
    let emailError;
    let passwordError;

    if (!isEmail(email)) {
      emailError = 'Введите корректный Email';
    }

    if (email.trim().length === 0) {
      emailError = 'Введите Email';
    }

    if (password.trim().length === 0) {
      passwordError = 'Введите пароль';
    }

    setErrors({ email: emailError, password: passwordError });

    if (emailError || passwordError) {
      return false;
    }

    try {
      await dispatch(signIn({email, password})).unwrap();
      navigate('profile/');
    } catch(error) {
      setErrors({ error });
    }
  }

  return (
    <Auth
      open={open}
      email={email}
      password={password}
      errors={errors}
      handleChange={setFieldsValues}
      handleSubmit={submit}
      handleClose={closePopup}
    />
  );
}
