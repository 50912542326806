import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subscribe } from '../../components';
import { selectors as tariffsSelectors } from '@store/tariffsSlice';
import { fetchPaymentHistory, selectors as paymentSelectors } from '@store/paymentSlice';
import {
  fetchPromocode,
  cleanPromocode,
  selectPromocode,
  selectPromocodeResult,
  selectRecurrentStatus,
  setPromocode,
  setRecurrentStatus,
} from '@store/paymentSlice';
import { cancelRecurrentPayment, selectSubscription } from '@store/usersSlice';
import { SubscribeWrapContainer } from '@containers';

export function SubscribeContainer() {
  const [lastPayment, setLastPayment] = useState();
  const promocode = useSelector(selectPromocode);
  const promocodeResult = useSelector(selectPromocodeResult);
  const recurrentStatus = useSelector(selectRecurrentStatus);
  const subscription = useSelector(selectSubscription);
  const currentTariff = useSelector((state) => tariffsSelectors.selectById(state, subscription?.tariff.id));
  const paymentHistory = useSelector(paymentSelectors.selectAll);
  const dispatch = useDispatch();

  // Убираем текущий тариф из списка
  function tariffsListHook(tariffsList) {
    if (currentTariff) {
      return tariffsList.filter((tariff) => currentTariff !== tariff);
    }

    return tariffsList;
  }

  // Применяем промокод
  function promocodeApply() {
    if (promocode) {
      dispatch(fetchPromocode({ promocode }));
    }
  }

  // Удаляем промокод
  function promocodeClean() {
    dispatch(cleanPromocode());
  }

  function promocodeChange(promocode) {
    if (promocode === '') {
      promocodeClean();
    } else {
      dispatch(setPromocode({ promocode }));
    }
  }

  // Обновляем значение автопродления подписки
  function recurrentChange() {
    if (subscription?.is_recurrent) {
      dispatch(cancelRecurrentPayment());
    } else {
      dispatch(setRecurrentStatus({ recurrentStatus: !recurrentStatus }));
    }
  }

  // Получаем историю платежей
  useEffect(() => {
    dispatch(fetchPaymentHistory());
  }, [dispatch]);

  // Получаем информацию о последнем оплаченном тарифе
  useEffect(() => {
    const completedPayment = paymentHistory.filter((payment) => (
      payment.hasOwnProperty('tariff') && payment.status === 'completed')
    );
    setLastPayment(completedPayment.pop());
  }, [paymentHistory]);

  // Устанавливаем текущее значение автопродления подписки
  useEffect(() => {
    dispatch(setRecurrentStatus({ recurrentStatus: subscription?.is_recurrent || false }));
  }, [dispatch, subscription?.is_recurrent]);

  return (
    <>
      {
        subscription
        && <SubscribeWrapContainer
            Component={Subscribe}
            currentTariff={currentTariff}
            lastPayment={lastPayment}
            subscription={subscription}
            recurrent={recurrentStatus}
            promocode={promocode}
            promocodeResult={promocodeResult}
            handleRecurrentChange={recurrentChange}
            handlePromocodeChange={promocodeChange}
            handlePromocodeApply={promocodeApply}
            handlePromocodeClean={promocodeClean}
            tariffsListHook={tariffsListHook}
        />
      }
    </>
  );
}
