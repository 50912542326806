// import style from './User.styl';
import { Title } from '@components';
import { Row, Section } from '../../components';
import {Button, Field} from "../../../../components";
import style from "../../../../components/FeedbackModal/Feedback.styl";
import {useState} from "react";
import {updatePassword} from "../../../../api/updatePassword";
// import { Checkbox } from '@components/Checkbox/Checkbox';

export function User({ user }) {
    const [newPassword,setNewPassword] = useState('');
    const [newPasswordConfirm,setNewPasswordConfirm] = useState('');
  return (
    <Section>
      <Section.Head>
        <Title tag="h2" size="small">Регистрация</Title>
      </Section.Head>
      {
        user
        && <Section.Content>
          <Row type="user">
            <Row.Icon type="email" width={20} height={16} />
            <Row.Title>{user.email}</Row.Title>
          </Row>
          {/*
          TODO: Раскомментировать когда появится API
          <Checkbox mod={style.subscribe} checked={true}>Подписаться на новости Чевостика</Checkbox>
          */}
              <Field
                  mod={style.field}
                  value={newPassword}
                  onChange={(value) => setNewPassword(value)}
              >
                  <Field.Label>Новый пароль</Field.Label>
                  <Field.Input type="Email" />
              </Field>
              <Field
                  mod={style.field}
                  value={newPasswordConfirm}
                  onChange={(value) => setNewPasswordConfirm(value)}
              >
                  <Field.Label>Подтвердите пароль</Field.Label>
                  <Field.Input type="Email" />
              </Field>
              <Button onClick={()=>{
              const newPassData = {
                  'password': newPassword,
                  'password_confirmation': newPasswordConfirm,

              };
              updatePassword(newPassData).then(()=>{
                console.log('success')
              })
          }}>Отправить</Button>
        </Section.Content>

      }
    </Section>
  );
}
