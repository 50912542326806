import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@components';
import { Banner } from '../../components';
import { selectSubscription } from '@store/usersSlice';
import { tariffsConfig } from '@utils/config';
import { devicesEnums, useDevice } from '@utils/hooks';
import { selectors } from '@store/tariffsSlice';

export function SpecialBannerContainer() {
  const subscription = useSelector(selectSubscription);
  const specialTariff = useSelector((state) => selectors.selectById(state, tariffsConfig.specialTariffId));

  // Определяем тип устройства
  const device = useDevice();

  return (
    <>
      {
        specialTariff
        && <Banner theme="specialFour">

            <div>Купи подписку <span style={{backgroundColor:'#E7C351',borderRadius:'20px',padding:'5px'}}>со скидкой 70%</span></div>
          <Banner.Title>Целый год всего&nbsp;за</Banner.Title>
          <Banner.Price>1990</Banner.Price>
          <Banner.OldPrice>6600</Banner.OldPrice>

            <Banner.Actions>
              <Button
                  customTag={Link}
                  to={subscription.status === 'soon_finished' ? `payment/${tariffsConfig.specialTariffId}` : `payment/${tariffsConfig.yearTariffId}`}
                  fullWidth={(device === devicesEnums.tablet)}
              >
                Купить

              </Button>
            </Banner.Actions>
          </Banner>
      }
    </>
  );
}
