import { Link } from 'react-router-dom';
import style from './EditButton.styl';
import cn from 'classnames';
import { useRowContext } from '../hooks';

export function EditButton({ to }) {
  const { type } = useRowContext();

  return (
    <Link
      to={to}
      className={cn(style.editButton, style[type])}
      title="Редактировать">
    </Link>
  );
}
