import style from './PageHead.styl';
import { Button } from '@components';
import { appConfig } from '@utils/config';
import * as images from './images';

export function PageHead() {
  return (
    <div className={style.pageHead}>
      <h1 className={style.title}>Учётные данные</h1>
      <Button customTag="a" href={appConfig.gameHost} color="yellow" mod={style.button}>
        <img src={images.Icon} width={32} height={20} alt=""/>
        Играть
        <img src={images.Arrow} width={15} height={14} alt=""/>
      </Button>
    </div>
  );
}
