import { getEnums, getParams } from '@utils/config';
import { AuthContainer, ForgotPasswordContainer, RegisterContainer } from '@containers';
import { DemoPopup } from '@components';
import { useGetParameter } from '@utils/hooks';

export function PopupsContainer() {
  const popups = {
    [getEnums.popup.signIn]: AuthContainer,
    [getEnums.popup.signUp]: RegisterContainer,
    [getEnums.popup.forgotPassword]: ForgotPasswordContainer,
    [getEnums.popup.demo]: DemoPopup,
  };

  const popupName = useGetParameter(getParams.popup);
  const theme = useGetParameter(getParams.theme);
  const Component = popups[popupName];

  if (!Component) {
    return null;
  }

  return (
    <Component open={Boolean(popupName)} theme={theme} />
  );
}
