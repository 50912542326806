import cn from 'classnames';
import style from './Banner.styl';
import { Subtitle } from './Subtitle/Subtitle';
import { Title } from './Title/Title';
import { Price } from './Price/Price';
import { OldPrice } from './OldPrice/OldPrice';
import { Comment } from './Comment/Comment';
import { Actions } from './Actions/Actions';
import { BannerProvider } from '@pages/Profile/components/Banner/hooks';

export function Banner({ theme, children }) {
  return (
    <BannerProvider value={{ theme }}>
      <div className={cn(style.banner, style[theme || 'default'])}>
        {children}
      </div>
    </BannerProvider>
  );
}

Banner.Subtitle = Subtitle;
Banner.Title = Title;
Banner.Price = Price;
Banner.OldPrice = OldPrice;
Banner.Comment = Comment;
Banner.Actions = Actions;
