import cn from 'classnames';
import style from './Subtitle.styl';
import { useTariffContext } from '../hooks';

export function Subtitle({children}) {
  const { theme } = useTariffContext();

  return (
    <div className={cn(style.subtitle, style[theme || 'default'])}>{children}</div>
  );
}
