import style from './ForgotPassword.styl';
import {Button, ErrorMessage, Field, Popup} from '@components';

export function ForgotPassword(props) {
  const {
    step,
    email,
    code,
    password,
    passwordConfirmation,
    open,
    errors,
    handleChange,
    handleNextStep,
    handleClose
  } = props;

  return (
    <Popup size="small" open={open} onClose={handleClose}>
      <Popup.Title>Восстановление пароля</Popup.Title>

      {
        (step === 1)
        && <Popup.Descr>
          Введите адрес email, указанный при регистрации
        </Popup.Descr>
      }

      {
        (step === 2)
        && <Popup.Descr>
          Введите код из письма
        </Popup.Descr>
      }

      {
        (step === 3)
        && <Popup.Descr>
          Придумайте новый пароль
        </Popup.Descr>
      }

      <Popup.Content>
        <div className={style.forgotPassword}>

          {
            (step === 1)
            && <Field
              mod={style.field}
              value={email}
              error={errors?.email}
              onChange={(value) => handleChange('email', value)}
            >
              <Field.Label>Email</Field.Label>
              <Field.Input type="email" />
            </Field>
          }

          {
            (step === 2)
            && <Field
              mod={style.field}
              value={code}
              error={errors?.code}
              onChange={(value) => handleChange('code', value)}
            >
              <Field.Label>Код</Field.Label>
              <Field.Input />
            </Field>
          }

          {
            (step === 3)
            && <>
              <Field
                mod={style.field}
                value={password}
                error={errors?.password}
                onChange={(value) => handleChange('password', value)}
              >
                <Field.Label>Новый пароль</Field.Label>
                <Field.Input type="password" />
              </Field>
              <Field
                mod={style.field}
                value={passwordConfirmation}
                error={errors?.passwordConfirmation}
                onChange={(value) => handleChange('passwordConfirmation', value)}
              >
                <Field.Label>Повторите пароль</Field.Label>
                <Field.Input type="password" />
              </Field>
              <div className={style.comment}>
                Пароль должен содержать заглавные,
                прописные буквы, цифры. Минимум 6 знаков.
              </div>

              {
                errors?.expiredError
                && <ErrorMessage>{errors.expiredError}</ErrorMessage>
              }

            </>
          }

          <Button mod={style.button} onClick={handleNextStep}>
            {(step < 3) ? 'Продолжить' : 'Сохранить'}
          </Button>
        </div>
      </Popup.Content>
    </Popup>
  );
}
