import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, MobileHeader } from '@components';
import { devicesEnums, useDevice } from '@utils/hooks';
import { routes } from './routes';

export function HeaderContainer({setFeedback}) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [mobileNavStatus, setMobileNavStatus] = useState(false);
  const location = useLocation();

  // Определяем тип устройства
  const device = useDevice();

  // Переключаем видимость мобильной навигации
  function toggleMobileNav() {
    setMobileNavStatus(!mobileNavStatus);
  }

  // Управляем состоянием выпадающего меню
  useEffect(() => {
    setDropdownStatus(false);
    setMobileNavStatus(false);
  }, [location])

  return (
    <>

      {
        (device === devicesEnums.pc)
        && <Header
              setFeedback={setFeedback}
          routes={routes}
          dropdownStatus={dropdownStatus}
          handleDropdownOpen={() => setDropdownStatus(true)}
          handleDropdownClose={() => setDropdownStatus(false)}
        />
      }

      {
        (device === devicesEnums.tablet)
        && <MobileHeader setFeedback={setFeedback} routes={routes} openNav={mobileNavStatus} handleToggleNav={toggleMobileNav} />
      }

    </>
  );
}
