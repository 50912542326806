/**
 * Интерполятор смещения элемента при скролле
 * в зависимости от прокрутки. Анимация начинается,
 * когда элемент достигает нижнего края экрана
 * @param {Number} proportion Коэффициент прокрутки
 * @param {Number} rate Коэффициент задержки
 * @param {Number} direction Направление движения элемента
 * @returns {{transform: string}}
 */
export function fromBottomInterpolator(proportion, rate, direction = 1) {
  if (proportion <= -1) {
    return { transform: `translateY(${direction * rate * 100}%)` };
  } else if (proportion > -1 && proportion <= 1) {
    return { transform: `translateY(${direction * (1 - proportion) * rate * 100}%)` };
  } else if (proportion > 1) {
    return { transform: 'translateY(0)' };
  }
}
