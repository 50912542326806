import { Link } from 'react-router-dom';
import style from './Register.styl';
import { Button, Field, Popup } from '@components';
import { appConfig } from '@utils/config';
import * as images from './images';

export function Register(props) {
  const {
    open,
    theme,
    email,
    password,
    passwordConfirmation,
    errors,
    handleChange,
    handleSubmit,
    handleClose
  } = props;

  return (
    <Popup open={open} theme={theme} onClose={handleClose}>
      <Popup.Title>Регистрация</Popup.Title>
      <Popup.Content>
        <div className={style.register}>
          <div className={style.form}>
            <Field
              mod={style.field}
              value={email}
              error={errors?.email}
              onChange={(value) => handleChange('email', value)}
            >
              <Field.Label>Ваш email</Field.Label>
              <Field.Input type="email" />
            </Field>
            <Field
              mod={style.field}
              value={password}
              error={errors?.password}
              onChange={(value) => handleChange('password', value)}
            >
              <Field.Label>Придумайте пароль</Field.Label>
              <Field.Input type="password" />
            </Field>
            <Field
              mod={style.field}
              value={passwordConfirmation}
              error={errors?.passwordConfirmation}
              onChange={(value) => handleChange('passwordConfirmation', value)}
            >
              <Field.Label>Повторите пароль</Field.Label>
              <Field.Input type="password" />
            </Field>
            <div className={style.comment}>
              Пароль должен содержать заглавные,<br />
              прописные буквы, цифры. Минимум 6 знаков.
            </div>
            <Button mod={style.button} onClick={handleSubmit}>Зарегистрироваться</Button>
          </div>
          <div className={style.services}>
            <h3 className={style.title}>
              Или используйте свой <Link className={style.link} to="?popup=sign-in">логин</Link>
            </h3>
            <Button
              customTag="a"
              href="/"
              mod={style.service}
              size="very-small"
              color="black"
              fullWidth={true}
            >
              <img src={images.vk} width={24} height={24} alt="" className={style.serviceIcon} />
              <span className={style.serviceName}>ВКонтакте</span>
            </Button>
            <Button
              customTag="a"
              href="/"
              mod={style.service}
              size="very-small"
              color="black"
              fullWidth={true}
            >
              <img src={images.fb} width={24} height={22} alt="" className={style.serviceIcon} />
              <span className={style.serviceName}>Facebook</span>
            </Button>
          </div>
        </div>
        <div className={style.agreement}>
          Регистрируясь, вы принимаете <a className={style.link} href={appConfig.eulaLink}>Правила использования</a> и <a className={style.link} href={appConfig.eulaLink}>Пользовательское соглашение</a>
        </div>
      </Popup.Content>
    </Popup>
  )
}
