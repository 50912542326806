import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import './styles/main.styl';
import { Home, Profile, NotFound, Eula, PrivacyPolicy } from '@pages';
import { LayoutDefault, LayoutHome, LayoutFullWidth } from '@layouts';
import { PrivateRouter } from '@components';
import { ChildEditContainer, DeleteChildContainer, PaymentContainer } from '@pages/Profile/containers';
import { fetchUser } from '@store/usersSlice';
import { isLoginIn } from '@utils/helpers';


// TODO: Перевести все изображения в Webp

export default function App() {
  const dispatch = useDispatch();
  const [feedback, setFeedback] = useState(false)

  // Получаем информацию о пользователе если он залогинен
  useEffect(() => {
    if (isLoginIn()) {
      dispatch(fetchUser());
    }
  }, [dispatch]);

  return (
    <>

      <Routes>

        <Route element={<LayoutDefault setFeedback={setFeedback}/>}>
          <Route path='*' element={<NotFound />} />
        </Route>

        <Route element={<LayoutHome setFeedback={setFeedback} />}>
          <Route path="/" element={<Home setFeedback={setFeedback} feedback={feedback}/>}>
            <Route path="payment/:tariffId" element={<PaymentContainer />} />
          </Route>
        </Route>

        <Route element={<LayoutFullWidth setFeedback={setFeedback}/>}>
          <Route element={<PrivateRouter />}>
            <Route path="profile/" element={<Profile />}>
              <Route path="children/add" element={<ChildEditContainer />} />
              <Route path="children/:childId" element={<ChildEditContainer />}>
                <Route path="confirm" element={<DeleteChildContainer />} />
              </Route>
              <Route path="payment/:tariffId" element={<PaymentContainer />} />
            </Route>
          </Route>
        </Route>

        <Route element={<LayoutDefault />}>
          <Route path='eula/' element={<Eula />} />
          <Route path='privacypolicy/' element={<PrivacyPolicy />} />
        </Route>

      </Routes>
    </>
  );
}
