import cn from 'classnames';
import style from './Comment.styl';
import { useTariffContext } from '../hooks';

export function Comment({ children }) {
  const { theme } = useTariffContext();

  return (
    <div className={cn(style.comment, style[theme || 'default'])}>
      {children}
    </div>
  );
}
