import { useNavigate } from 'react-router-dom';
import { Demo, Popup } from '@components';

export function DemoPopup({ open, theme }) {
  const navigate = useNavigate();

  return (
    <Popup open={open} theme={theme} onClose={() => navigate(-1)}>
      <Popup.Content>
        <Demo type="popup" />
      </Popup.Content>
    </Popup>
  );
}
