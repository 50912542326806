import { api } from '@utils/helpers';
import {apiV2} from "../utils/helpers";

/**
 * @typedef {Object} Tariff
 */

/**
 * Получаем список тарифов
 * @returns {Promise<[Tariff]>}
 */
export async function getQuests(id) {
  try {
    const response = await apiV2.get(`/quests?child_id=${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}
