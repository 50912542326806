import { Title } from '@components';
import { Row, Section } from '../../components';
import { dateFormat } from '@utils/helpers';
import { appConfig } from '@utils/config';

export function Children({ childrenList }) {
  return (
    <Section>
      <Section.Head>
        <Title tag="h2" size="small">Дети</Title>
        <Section.AddButton to="children/add"></Section.AddButton>
      </Section.Head>
      {
        childrenList
        && <Section.Content>
          {
            childrenList.map((child) => (
              <Row key={child.id} type="child">
                <Row.Avatar src={`${appConfig.gameHost}${child.avatar || appConfig.defaultAvatar}`} />
                <Row.Title>{child.name}</Row.Title>
                <Row.DateOfBirth>{dateFormat(child.birthday)}</Row.DateOfBirth>
                <Row.EditButton to={`children/${child.id}`} />
              </Row>
            ))
          }
        </Section.Content>
      }
    </Section>
  );
}
