import style from './DeleteChild.styl';
import { Button, Popup } from '@components';

export function DeleteChild({ handleDelete, handleClose }) {
  return (
    <Popup size="small" onClose={handleClose}>
      <Popup.Title>Удалить ребенка</Popup.Title>
      <Popup.Descr>Вы уверены?</Popup.Descr>
      <Popup.Content>
        <div className={style.deleteChild}>
          <Button mod={style.button} color="blueBorder" onClick={handleClose}>Оставить</Button>
          <Button mod={style.button} color="red" onClick={handleDelete}>Удалить</Button>
        </div>
      </Popup.Content>
    </Popup>
  );
}
