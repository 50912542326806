import { api } from '@utils/helpers';

/**
 * @typedef {Object} Payment
 */

/**
 * Получаем историю платежей
 * @returns {Promise<[Payment]>}
 */
export async function getPaymentHistory() {
  try {
    const response = await api.get('/payments');
    return response.data;
  } catch(error) {
    throw new Error('Server error!');
  }
}

/**
 * Получаем данные для оплаты подписки
 * @param {Number} tariff_id ИД тарифа
 * @param {String<'0'|'1'>} is_recurrent Повтор платежа
 * @param {String} promo_code Промокод
 * @returns {Promise<Object>}
 */
export async function getPaymentData(tariff_id, is_recurrent, promo_code) {
  try {
    const data = { tariff_id, is_recurrent };

    if (promo_code?.length > 0) {
      data.promo_code = promo_code
    }

    const response = await api.post('/payments', data);
    return response.data;
  } catch (error) {
    throw new Error('Server error!');
  }
}

/**
 * Проверяем промокод и получаем данные о скидке
 * @param {string} promocode Промокод
 * @returns {Promise<Object>}
 */
export async function getPromocode(promocode) {
  try {
    const response = await api.get(`/promo_codes/search?code=${promocode}`);
    return response.data;
  } catch (error) {
    throw new Error('Server error!');
  }
}
