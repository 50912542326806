import { Link } from 'react-router-dom';
import style from './Promo.styl';
import { Button } from '@components';
import { appConfig, getEnums } from '@utils/config';
import { devicesEnums, useAuth, useDevice } from '@utils/hooks';
import * as images from './images';

export function Promo() {
  const device = useDevice();
  const isLoginIn = useAuth();

  return (
    <div className={style.promo}>
        <div className={style.container}>
            <div className={style.content}>
                 <div>
                        Развитие детей <br/>
                        <span style={{color: '#c900ea'}}> 3-8 лет</span> и познание мира <br/>
                        с увлекательными<br/>
                        <span style={{color: '#c900ea'}}>интерактивными</span> уроками<br/>
                        <span style={{fontSize: '25px', fontWeight: '400'}}>Ваш ребенок будет в восторге!</span>
                    </div>



                <div className={style.promo__block__wrapper}>
                    <img className={style.image} src={images.che} alt=""
                    />
                    {
                        (!isLoginIn && device === devicesEnums.tablet)
                        && <div style={{marginBottom:'150px'}}><Button
                            customTag={Link}
                            to={`?popup=${getEnums.popup.signUp}&theme=green`}
                            mod={style.button}
                            color="green"
                            size={'small'}
                        >
                            Попробовать сейчас
                        </Button>
                        </div>
                    }
                    <div className={style.promo__block}>40+<br/>
                        <span style={{fontSize: '30px'}}>тем<br/></span>
                        <div style={{lineHeight: '12px'}}>

                        <span
                            style={{fontSize: '12px', fontWeight: '400'}}>от древнего мира<br/>до полетов на марс</span>
                        </div>
                    </div>
                    <div
                        className={style.promo__block__center}
                        >100<br/>
                        <span style={{fontSize: '30px'}}>уроков<br/></span>
                        <div style={{lineHeight: '12px'}}>

                        <span
                            style={{fontSize: '12px', fontWeight: '400'}}>проверенная информация<br/>от научных редакторов</span>
                        </div>
                    </div>
                    <div className={style.promo__block}>350000+<br/>
                        <span style={{fontSize: '30px'}}>семей<br/></span>
                        <div style={{lineHeight: '12px'}}>

                        <span
                            style={{fontSize: '12px', fontWeight: '400'}}>доверили нам<br/>развитие детей</span>
                        </div>
                    </div>
                </div>
            </div>


             <div className={style.buttonWrap}>

                {
                    isLoginIn
                    && <Button
                        customTag="a"
                        href={appConfig.gameHost}
                        mod={style.button}
                        color="green"
                        size={(device === devicesEnums.pc) ? 'large' : 'small'}
                    >
                        Играть
                    </Button>
                }

                {
                    (!isLoginIn && device === devicesEnums.pc)
                    && <Button
                        customTag={Link}
                        to={`?popup=${getEnums.popup.signUp}&theme=green`}
                        mod={style.button}
                        color="green"
                        size={(device === devicesEnums.pc) ? 'large' : 'small'}
                    >
                        Попробовать сейчас
                    </Button>
                }

            </div>
        </div>
    </div>
  );
}
