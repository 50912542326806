import { SubscribeWrapContainer } from '@containers';
import { Subscribe } from '../../components';
import {useDispatch, useSelector} from "react-redux";
import {
  cleanPromocode,
  fetchPromocode,
  selectPromocode,
  selectPromocodeResult,
  selectRecurrentStatus, setPromocode, setRecurrentStatus
} from '@store/paymentSlice';
import {cancelRecurrentPayment, selectSubscription} from '@store/usersSlice';

export function SubscribeContainer() {
  const promocode = useSelector(selectPromocode);
  const promocodeResult = useSelector(selectPromocodeResult);
  const recurrentStatus = useSelector(selectRecurrentStatus);
  const subscription = useSelector(selectSubscription);
  const dispatch = useDispatch();

  // Применяем промокод
  function promocodeApply() {
    if (promocode) {
      dispatch(fetchPromocode({ promocode }));
    }
  }

  // Удаляем промокод
  function promocodeClean() {
    dispatch(cleanPromocode());
  }

  function promocodeChange(promocode) {
    if (promocode === '') {
      promocodeClean();
    } else {
      dispatch(setPromocode({ promocode }));
    }
  }

  // Обновляем значение автопродления подписки
  function recurrentChange() {
    if (subscription?.is_recurrent) {
      dispatch(cancelRecurrentPayment());
    } else {
      dispatch(setRecurrentStatus({ recurrentStatus: !recurrentStatus }));
    }
  }

  return <SubscribeWrapContainer
    Component={Subscribe}
    recurrent={recurrentStatus}
    subscription={subscription}
    promocode={promocode}
    promocodeResult={promocodeResult}
    handleRecurrentChange={recurrentChange}
    handlePromocodeChange={promocodeChange}
    handlePromocodeApply={promocodeApply}
    handlePromocodeClean={promocodeClean}
  />
}
