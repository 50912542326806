import { Link, useMatch } from 'react-router-dom';
import { animateScroll as scroll, Link as ScrollLink } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import cn from 'classnames';
import style from './MobileHeader.styl';
import { Button } from '@components';
import { appConfig, getEnums } from '@utils/config';
import { useAuth } from '@utils/hooks';
import * as images from './images';

export function MobileHeader({ routes, openNav, handleToggleNav,setFeedback }) {
  const isLoginIn = useAuth();
  const matchHome = useMatch('/');

  return (
    <>
      <div className={style.header}>

        {
          matchHome
          && <div
            className={style.logoLink}
            onClick={() => scroll.scrollToTop({ containerId: 'scroll-home' })}
          >
            <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" />
          </div>
        }

        {
          !matchHome
          && <Link
            className={style.logoLink}
            to="/"
          >
            <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" />
          </Link>
        }

        {
          isLoginIn
          && <Button
            customTag="a"
            href={appConfig.gameHost}
            size="very-small"
            mod={style.button}
          >
            Играть
          </Button>
        }

        {
          !isLoginIn
          && <Button
            customTag={Link}
            to={`?popup=${getEnums.popup.signUp}&theme=green`}
            mod={style.button}
            color="green"
            size="very-small"
          >
            Попробовать сейчас
          </Button>
        }

        <button
          className={
            cn(
              style.toggleNavButton,
              { [style.isLogin]: isLoginIn}
            )
          }
          onClick={handleToggleNav}
          type="button"
        >
          <img src={images.menu} width={20} height={16} alt="" />
        </button>
      </div>
      <div className={cn(style.nav, { [style.open]: openNav })}>


        {
          matchHome
          && <ScrollLink
            to="subscribe"
            containerId="scroll-home"
            smooth={true}
            hashSpy={true}
            offset={-90}
            className={style.navLink}
            onClick={handleToggleNav}
          >
            Подписка
          </ScrollLink>
        }

        {
          !matchHome
          && <HashLink
            to={routes.tariffs}
            className={style.navLink}
          >
            Подписка
          </HashLink>
        }

        <a href={routes.shop} className={style.navLink}>Магазин</a>
          <span onClick={() => {
              setFeedback(true)
          }} className={style.navLink}>Контакты</span>

          {
              isLoginIn
              && <>
                  <Link to="/profile" className={style.navLink}>Учетные данные</Link>
            <Link to="?sign-out=y" className={style.navLink}>Выход</Link>
          </>
        }

        {
          !isLoginIn
          && <Link to={`?popup=${getEnums.popup.signIn}`} className={style.navLink}>Войти</Link>
        }

      </div>
    </>
  );
}
