import style from './Title.styl';
import cn from 'classnames';

export function Title({ type, size, mod, children }) {
  let Tag = 'div';

  if (['h1', 'h2', 'h3'].includes(type)) {
    Tag = type;
  }

  return (
    <Tag className={cn(style.title, style[size || 'normal'], mod)}>{children}</Tag>
  );
}
