import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ForgotPassword } from '@components';
import { isEmail } from '@utils/helpers';
import {useDispatch} from "react-redux";
import {resetPassword, setNewPassword, setResetToken} from "@store/usersSlice";

export function ForgotPasswordContainer({ open }) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function closePopup() {
    navigate(-1);
  }

  // Получаем данные из формы
  function setFieldsValues(field, value) {
    switch(field) {
      case 'email':
        setEmail(value);
        break;
      case 'code':
        setCode(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'passwordConfirmation':
        setPasswordConfirmation(value);
        break;
      default:
        break;
    }
  }

  // Отправляем данные на сервер
  async function nextStep() {
    // Проверяем поля на ошибки
    let emailError;
    let codeError;
    let passwordError;
    let passwordConfirmationError;

    if (step === 1 && !isEmail(email)) {
      emailError = 'Введите корректный Email';
    }

    if (step === 1 && email.trim().length === 0) {
      emailError = 'Введите Email';
    }

    if (step === 2 && code.trim().length === 0) {
      codeError = 'Введите Код';
    }

    if (step === 3 && password.trim().length === 0) {
      passwordError = 'Введите пароль';
    }

    if (step === 3 && passwordConfirmation.trim() !== password.trim()) {
      passwordError = 'Пароли не совпадают';
      passwordConfirmationError = 'Пароли не совпадают';
    }

    if (emailError || codeError || passwordError || passwordConfirmationError) {
      setErrors({
        email: emailError,
        code: codeError,
        password: passwordError,
        passwordConfirmation: passwordConfirmationError
      });

      return false;
    }

    let requestEmailError;
    let requestExpiredError;

    // Отправляем запрос на получение токена
    if (step === 1) {
      try {
        await dispatch(resetPassword({ email })).unwrap();
      } catch (error) {
        requestEmailError = error;
      }
    }

    // Сохраняем токен
    if (step === 2) {
      dispatch(setResetToken({ resetPasswordToken: code }));
    }

    // Отправляем запрос на изменение пароля
    if (step === 3) {
      try {
        await dispatch(setNewPassword({ password, passwordConfirmation })).unwrap();
        navigate('profile/');
      } catch (error) {
        requestExpiredError = error;
      }
    }

    if (requestEmailError || requestExpiredError) {
      setErrors({
        email: requestEmailError,
        expiredError: requestExpiredError
      });

      return false;
    }

    if (step < 3) {
      setStep(step + 1);
    }
  }

  return (
    <ForgotPassword
      email={email}
      code={code}
      password={password}
      passwordConfirmation={passwordConfirmation}
      step={step}
      open={open}
      errors={errors}
      handleChange={setFieldsValues}
      handleNextStep={nextStep}
      handleClose={closePopup}
    />
  );
}
