
import style from './Quests.styl';
import { Title } from '@components';
import {Section, Payment} from '../../components';
import { appConfig } from '@utils/config';
import * as images from './images';

import {getQuests} from "../../../../api/quests";
import {getChildren} from "../../../../api/children";
import {useEffect, useState} from "react";
import {getPaymentQuest} from "../../../../api/paymentQuest";
import {getPaymentHistory} from "../../../../api/payment";


function buyQuest(id,children,setToken) {

  getPaymentQuest({quest_id:id, child_id: children, quantity: 1}).then((res)=>{

    setToken(res.confirmation_token)
  })
}
export function Quests() {



  let children;
  const [quests,setQuest] = useState();
  const [paymentsHistory,setPaymentsHistory] = useState(null);
  useEffect(() => {
    getPaymentHistory().then((resp)=>{
      setPaymentsHistory(resp)
    })
  }, []);

  const [token,setToken] = useState(null);
  useEffect(() => {

    getChildren().then((element)=>{
      children = element[0].id;
      getQuests(children).then((res)=>{
        setQuest(res.filter((element)=> element.category === 'paid').map((item)=>{
         let paid = false;
         if(paymentsHistory !== null){
           for(let i = 0; i < paymentsHistory.length; i++){
             if(paymentsHistory[i].quest){
               if(paymentsHistory[i].quest.id === item.id && paymentsHistory[i].status === 'completed')
                 paid = true;
             }
           }
         }

          return(
              <div key={item.id} onClick={()=>{
                if(paid === false){
                  buyQuest(item.id,children,setToken)
                }
                if(paid === true){
                    window.location.replace(appConfig.gameHost + `/quests/${item.id}`)
                }

              }} style={{cursor:'pointer',display:'block',backgroundColor:`${paid ? '#FFD597' : 'white'}`, width:'216px', height:'240px', marginRight:'20px',marginBottom:'20px',borderRadius:'40px', padding:'40px'}}>
              <div style={{fontSize:'24px', fontWeight:'900',height:'60px'}}>{item.name}</div>
              <div  style={{fontSize:'28px', fontWeight:'900'}}>{item.price}₽</div>
              <div  style={{fontSize:'14px', marginTop:'40px'}}>Доступен навсегда</div>
                {paid === false && (<div style={{
                  backgroundColor: '#0066FF',
                  color: 'white',
                  width: '206px',
                  height: '48px',
                  borderRadius: '24px',
                  lineHeight: '48px',
                  textAlign: 'center',
                  marginTop: '40px'
                }}>Оплати и изучай</div>)}
                  {paid === true && (<div style={{
                      backgroundColor: '#0066FF',
                      color: 'white',
                      width: '206px',
                      height: '48px',
                      borderRadius: '24px',
                      lineHeight: '48px',
                      textAlign: 'center',
                      marginTop: '40px'
                  }}>Играть</div>)}
              </div>
          )
        }))
      })
    });
  }, [paymentsHistory]);


  return (
      <div style={{
        backgroundColor: '#FFECCF',
        borderRadius: '60px',
        padding: '40px',
        marginTop: '20px',marginBottom:'20px'}} id="subscribe">
      <Section.Head>
        <Title tag="h2" size="small">Маршруты</Title>
      </Section.Head>
      <div>

        <div className={style.items} >

          {quests}
          {token && (<Payment token={token} backUrl="https://chevostik.ru/profile" handleClose={()=>setToken(null)}/>)}
        </div>
        <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" className={style.logo}/>

      </div>
    </div>
  );
}
