import { apiV2 } from '@utils/helpers';

/**
 * @typedef {Object} Child
 */

/**
 * Получаем список детей
 * @returns {Promise<[Child]>}
 */
export async function getChildren() {
  try {
    const response = await apiV2.get('/children');
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}

/**
 * Добавляем ребенка
 * @param {Object} child Данные о ребенке
 * @returns {Promise<Child>}
 */
export async function postChild(child) {
  try {
    const response = await apiV2.post('/children', child);
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      throw new Error(`Имя ${error.response.data.errors.name[0]}`);
    }
  }
}

/**
 * Обновляем ребенка
 * @param {Number} id ID ребенка
 * @param {Object} child Данные о ребенке
 * @returns {Promise<Child>}
 */
export async function putChild(id, child) {
  try {
    const response = await apiV2.put(`/children/${id}`, child);
    return response.data;
  } catch (error) {
    if (error.response.status === 422) {
      throw new Error(`Имя ${error.response.data.errors.name[0]}`);
    }
  }
}

// TODO: В API нет метода для удаления ребенка

/**
 * Удаляем ребенка
 * @param {Number} id ID ребенка
 * @returns {Promise<Child>}
 */
export async function deleteChild(id) {
  try {
    const response = await apiV2.delete(`/children/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}
