import { useRef } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import style from './Header.styl';
import { Button } from '@components';
import { appConfig, getEnums } from '@utils/config';
import { useAuth, useOutsideAlerter } from '@utils/hooks';
import * as images from './images';

export function Header(props) {
  const {
    routes,
    dropdownStatus,
    handleDropdownOpen,
    handleDropdownClose,
    setFeedback
  } = props;

  const isLoginIn = useAuth();
  const matchHome = useMatch('/');

  // Запускаем событие по клику вне выпадающего меню
  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, handleDropdownClose);

  return (
    <header className={style.header}>
      <div className={style.inner}>

        {
          matchHome
          && <div
            className={style.logoLink}
            onClick={() => scroll.scrollToTop({ containerId: 'scroll-home' })}
          >
            <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" className={style.logo}/>
          </div>
        }

        {
          !matchHome
          && <Link
            className={style.logoLink}
            to="/"
          >
            <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" className={style.logo}/>
          </Link>
        }

        <Button customTag="a" href={appConfig.gameHost} size="very-small">Играть</Button>
 {/*       <a href={routes.about} className={style.navLink}>О нас</a>*/}

        {
          matchHome
          && <ScrollLink
            to="subscribe"
            containerId="scroll-home"
            smooth={true}
            hashSpy={true}
            className={style.navLink}
          >
            Подписка
          </ScrollLink>
        }

        {
          !matchHome
          && <HashLink
            to={routes.tariffs}
            className={style.navLink}
          >
            Подписка
          </HashLink>
        }

        <a href={routes.shop} className={style.navLink}>Магазин</a>
        <span onClick={()=>{setFeedback(true)}} className={style.navLink}>Контакты</span>

        {
          isLoginIn
          && <div className={style.dropdownWrap}>
            <img src={images.avatar} width={38} height={38} className={style.avatar} onClick={handleDropdownOpen} alt="" />
            {
              dropdownStatus
              && <div ref={dropdownRef} className={style.dropdown}>
                <Link to="/profile" className={style.dropdownLink}>Учетные данные</Link>
                <Link to="?sign-out=y" className={style.dropdownLink}>Выход</Link>
              </div>
            }
          </div>
        }

        {
          !isLoginIn
          && <>
              <Button
                customTag={Link}
                to={`?popup=${getEnums.popup.signUp}&theme=green`}
                mod={style.button}
                color="green"
                size="very-small"
              >
                Попробовать сейчас
              </Button>
              <Link to={`?popup=${getEnums.popup.signIn}`} className={style.navLink}>Войти</Link>
            </>
        }

      </div>
    </header>
  );
}
