import style from './Popup.styl';
import cn from 'classnames';
import { Title } from './Title/Title';
import { Descr } from './Descr/Descr';
import { Content } from './Content/Content';
import * as images from './images';



export function Popup({ theme, size, children, onClose }) {
  return (
    <div className={style.popup}>
      <div className={cn(style.inner, style[theme || 'default'], style[size || 'normal'])}>
        {children}
        <button className={style.closeBtn} type="button" onClick={onClose} title="Закрыть">
          <img src={images.close} width={12} height={12} alt="" />
        </button>
      </div>
      <div className={style.overlay} onClick={onClose}></div>
    </div>
  );
}

Popup.Title = Title;
Popup.Descr = Descr;
Popup.Content = Content;
