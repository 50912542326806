import { Link } from 'react-router-dom';
import cn from 'classnames';
import style from './Demo.styl';
import { Title, Button } from '@components';
import { appConfig, getEnums } from '@utils/config';
import { useAuth } from '@utils/hooks';
import * as images from './images';

export function Demo({ type }) {
  const isLoginIn = useAuth();

  return (
    <section className={cn(style.demo, style[type || 'default'])}>
      <div className={style.inner}>
        <Title type="h2" mod={style.title}>Попробуйте бесплатно</Title>
        <div className={style.subtitle}>
          Мы уверены, что вам понравится.
        </div>
        <div className={style.trial}>
          <h3 className={style.sectionTitle}>
            Полный доступ<br/>
            на три дня
          </h3>
          <div className={style.descr}>
            Весь функционал,<br/>
            без ограничений.
          </div>
        </div>
        <img src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" className={style.che}/>
        <div className={style.free}>
          <h3 className={style.sectionTitle}>
            Десять уроков<br/>
            насовсем
          </h3>
          <div className={style.descr}>
            Пробуйте,<br/>
            не торопитесь.
          </div>
        </div>
        <div className={style.button}>

          {
            isLoginIn
            && <Button customTag="a" href={appConfig.gameHost}>Играть</Button>
          }

          {
            !isLoginIn
            && <Button
              customTag={Link}
              to={`?popup=${getEnums.popup.signUp}&theme=green`}
            >
              Начать сейчас
            </Button>
          }

        </div>
      </div>
    </section>
  );
}
