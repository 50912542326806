
import cn from 'classnames';
import style from './SecondPromo.styl';
import { Title, Button } from '@components';
import * as images from './images';

import { useAuth } from '@utils/hooks';


export function SecondPromo({ type }) {
  const isLoginIn = useAuth();

  return (
      <section className={cn(style.demo, style[type || 'default'])}>
      <div className={style.wrapper}>


        <div className={style.inner}>
            <Title type="h2" mod={style.title}>Более 3 000 000 детей познакомилось с нашими героями за 22 года</Title>

            <div className={style.promo__block__wrapper}>
                <div className={style.promo__block}>100+<br/>
                    <span style={{fontSize: '20px'}}>Интерактивных уроков<br/></span>
                </div>
                <div
                    className={style.promo__block__center}
                >40+<br/>
                    <span style={{fontSize: '30px'}}>Книг<br/></span>
                </div>
                <div className={style.promo__block}>100+<br/>
                    <span style={{fontSize: '20px'}}>Образовательных пдф<br/></span>
                </div>
            </div>
            <img className={style.image} src={images.che}/>
        </div>
      </div>
      </section>
  );
}
