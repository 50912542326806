import style from './Feedback.styl';
import {Field} from "../Field/Field";
import {useEffect, useState} from "react";
import {Button} from "../Button/Button";
import {sendFeedback} from "../../api/feedback";
import {Title} from "../Title/Title";
import {getUser} from "../../api/users";


export function Feedback({setFeedback}) {
    const [messageSend, setMessageSend] = useState(false)
    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [theme, setTheme] = useState('Нужна помощь');
    const [message, setMessage] = useState();
    const [userId,setUserId] = useState('none');

    const [nameError,setNameError] = useState(null);
    const [messageError,setMessageError] = useState(null);
    const [emailError,setEmailError] = useState(null);
const options=[
    {value:'Нужна помощь',title:'Нужна помощь'},
    {value:'Отзыв',title:'Отзыв'},
    {value:'Партнерство',title:'Партнерство'},
    {value:'Другое',title:'Другое'},
]
useEffect(()=>{
    getUser().then((e)=>{
        setUserId(e.id)
    })
},[])
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
  return (
      <>
    <div className={style.popup}>
    <div className={style.feedbackWindow}>
        <span onClick={()=>{
            setFeedback(false)
        }} className={style.navLink}>X</span>
        {!messageSend && (
            <>
                <Title>Напишите нам</Title>
                <Field
                    mod={style.field}
                    value={name}
                    onChange={(value) => setName(value)}
                >
                    <Field.Label>Как вас зовут?</Field.Label>
                    <Field.Input type="Имя" />
                </Field>
                {nameError && (
                    <span style={{color: 'red', marginLeft: '25px'}}>{nameError}</span>
                )}
                <Field
                    mod={style.field}
                    value={email}

                    onChange={(value) => setEmail(value)}
                >
                    <Field.Label>Email для ответа</Field.Label>
                    <Field.Input type="Email" />
                </Field>
                {emailError && (
                    <span style={{color: 'red', marginLeft: '25px'}}>{emailError}</span>
                )}
                <Field   mod={style.field} value={theme}        onChange={(value) => setTheme(value)}>
                    <Field.Label>О чем ваше письмо?</Field.Label>
                    <Field.Select options={options}/>
                </Field>
                <Field
                    mod={style.field}
                    value={message}
                    onChange={(value) => setMessage(value)}
                >
                    <Field.Label>Сообщение</Field.Label>
                    <Field.Input type="Email" />
                </Field>
                {messageError && (
                    <span style={{color: 'red', marginLeft: '25px'}}>{messageError}</span>
                )}
                <div style={{marginTop:'25px'}}></div>

                <Button onClick={()=>{
                    const feedback = {
                        'name': name,
                        'email': email,
                        'subject': theme,
                        'message': message + '  UserID:' + userId,
                    };
                    if(!name){
                        setNameError('Введите имя')
                    } else {
                        setNameError(null)
                    }

                    if(!email){
                        setEmailError('Введите Ваш e-mail')
                    } else {
                        setEmailError(null)
                        if(validateEmail(email)){
                            setEmailError(null)
                        } else {
                            setEmailError('Введите корректный e-mail')
                        }
                    }
                    if(!message){
                        setMessageError('Введите Ваше сообщение')
                    } else {
                        setMessageError(null)
                    }
                    if(name && validateEmail(email) && message){
                        sendFeedback(feedback).then(()=>{
setMessageSend(true)
                        })
                    }

                }}>Отправить</Button>
            </>
        )}
        {messageSend && (<div style={{justifyContent:'center',textAlign:'center'}}><div style={{marginBottom:'25px',fontWeight:'bold'}}>Спасибо за обратную связь!</div> <Button onClick={()=>{
            setFeedback(false)
        }}>Закрыть</Button></div>)}

    </div>
    </div>
      </>
  );
}


