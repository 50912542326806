import moment from 'moment';

/**
 * Количество дней до окончания подписки
 * @param {String} dueDate Дата окончания подписки
 * @returns {String}
 */
export function daysToSubscriptionDue(dueDate) {
  const values = ['Один день', 'Два дня', 'Три дня'];
  const startDate = moment();
  const endDate = moment(dueDate);
  const diff = endDate.diff(startDate, 'days');
  return values[diff] ? values[diff] : 'Ноль дней';
}
