import cn from 'classnames';
import style from './ErrorMessage.styl';

export function ErrorMessage({ mod, align, children }) {
  return (
    <div className={cn(style.error, style[align || 'right'], mod)}>
      {children}
    </div>
  );
}
