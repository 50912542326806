import style from './Step.styl';
import { toTopInterpolator } from '@utils/helpers';

export function Step(props) {
  const {
    proportion,
    stepName,
    image,
    imageRetina,
    title,
    children
  } = props;

  return (
    <section className={style.step}>
      <div className={style.stepName} style={toTopInterpolator(proportion, 0.5, 1)}>{stepName}</div>
      <img className={style.image} src={image} srcSet={`${imageRetina} 2x`} alt=""/>
      <h3 className={style.title}>{title}</h3>
      <div className={style.descr}>
        {children}
      </div>
    </section>
  );
}
