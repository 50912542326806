import cn from 'classnames';
import style from './Promocode.styl';
import { Button, Field } from '@components';

export function Promocode(props) {
  const {
    promocode,
    promocodeResult,
    handleChange,
    handleApply,
    handleClean
  } = props;

  // Обрабатываем ошибки
  let errorMessage = null;

  switch(promocodeResult?.result) {
    case 0:
      errorMessage = 'Промокод не найден';
      break;
    case 1:
      errorMessage = 'Промокод не активен';
      break;
    default:
      break;
  }

  return (
    <section className={style.promocode}>
      <h3 className={style.title}>У вас есть промокод?</h3>
      <Field
        mod={cn(style.input, { [style.successInput]: (promocodeResult?.result === 2) })}
        error={errorMessage}
        value={promocode}
        onChange={handleChange}
        onClean={handleClean}
        disabled={promocodeResult?.result === 2}
      >
        <Field.Input />
        <Field.CleanButton />
      </Field>

      {
        promocodeResult?.result !== 2
        && <Button mod={style.button} size="small" onClick={handleApply}>Применить</Button>
      }

      {
        promocodeResult?.result === 2
        && <div className={style.successMessage}>Промокод сработал</div>
      }

    </section>
  );
}
