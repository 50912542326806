import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { deleteChild, getChildren, postChild, putChild } from '@api/children';

export const fetchChildren = createAsyncThunk(
  'children/fetchChildren',
  async function(_, { rejectWithValue }) {
    try {
      return await getChildren();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addChild = createAsyncThunk(
  'children/addChild',
  async function({ child }, { rejectWithValue }) {
    try {
      return await postChild(child);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateChild = createAsyncThunk(
  'children/updateChild',
  async function({ id, child }, { rejectWithValue }) {
    try {
      return await putChild(id, child);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeChild = createAsyncThunk(
  'children/deleteChild',
  async function({ id }, { rejectWithValue }) {
    try {
      return await deleteChild(id);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const childrenAdapter = createEntityAdapter({
  selectId: (child) => child.id,
  sortComparer: (a, b) => (a.id < b.id) ? 1 : -1
});

const setError = (state, action) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const childrenSlice = createSlice({
  name: 'children',
  initialState: childrenAdapter.getInitialState({
    status: null,
    error: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChildren.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchChildren.fulfilled, (state, action) => {
        childrenAdapter.setAll(state, action.payload);
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchChildren.rejected, setError)
      .addCase(addChild.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addChild.fulfilled, (state, action) => {
        childrenAdapter.addOne(state, action.payload)
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(addChild.rejected, setError)
      .addCase(updateChild.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateChild.fulfilled, (state, action) => {
        childrenAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            name: action.payload.name,
            birthday: action.payload.birthday
          }
        });
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(updateChild.rejected, setError)
      .addCase(removeChild.rejected, setError);
  }
});

export const selectors = childrenAdapter.getSelectors((state) => state.children);

export default childrenSlice.reducer;
