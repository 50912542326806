import cn from 'classnames';
import style from './CleanButton.styl';
import * as images from './images';
import { useFieldContext } from '@components/Field/hooks';

export function CleanButton() {
  const { theme, value, onClean } = useFieldContext();

  return (
    <>
      {
        (value.length > 0)
        && <button
          className={cn(style.button, style[theme || 'default'])}
          onClick={onClean}
          title="Очистить поле"
          type="button"
        >
          <img className={style.icon} src={images.icon} width={12} height={12} alt="" />
        </button>
      }
    </>
  )
}
