import cn from 'classnames';
import style from './Descr.styl';
import { useTariffContext } from '../hooks';

export function Descr({ children }) {
  const { theme } = useTariffContext();

  return (
    <div className={cn(style.descr, style[theme || 'default'])}>
      {children}
    </div>
  );
}
