import style from './MethodologyTitle.styl';
import { Title } from '@components';
import { Section } from '../../components';
import {devicesEnums, useDevice} from "@utils/hooks";

export function MethodologyTitle() {
  const device = useDevice();

  return (
    <Section fullHeight={device === devicesEnums.pc}>
      <Title type="h2" mod={style.title}>
        Наша уникальная методология:<br/>
        Система закрепления информации<br/>
        в три шага
      </Title>
    </Section>
  );
}
