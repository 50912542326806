import style from './Arts.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator } from '@utils/helpers';
import { appConfig } from '@utils/config';
import * as images from './images';

// TODO: Объединить в один компонент с Verified
export function Arts() {
  return (
    <StickyView>
      {(proportion) => (
        <Section>
          <div className={style.arts}>
            <div>
              <img src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" className={style.che}/>
            </div>
            <div className={style.content} style={toTopInterpolator(proportion, 0.6)}>
              <Title type="h2" mod={style.title}>
                Лучшие<br/>
                художники
              </Title>
              <div className={style.descr}>
                Мастера иллюстрации<br/>
                создают миры<br/>
                Приключений Чевостика
              </div>
              <a href={`${appConfig.gameHost}/about`} className={style.link}>Подробнее</a>
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
