import moment from 'moment';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function generateDaysArray(limit) {
  return Array.from({ length: limit }, (_, i) => {
    return {
      value: i + 1,
      title: String(i + 1).padStart(2, '0')
    }
  });
}

function generateMonthsArray() {
  const date = new Date();

  return Array.from({ length: 12 }, (_, i) => {
    date.setMonth(i);

    return {
      value: i,
      title: capitalizeFirstLetter(date.toLocaleDateString(undefined, { month: 'long' }))
    }
  });
}

function generateYearsArray(limit) {
  let years = [];
  const yearStart = moment();
  const yearEnd = moment().subtract(limit, 'y');

  while (yearStart.diff(yearEnd, 'y') >= 0) {
    const year = yearStart.format('yyyy');

    years.push({
      value: year,
      title: year
    });

    yearStart.subtract(1, 'y');
  }

  return years;
}

/**
 * Формируем список для выбора даты
 * рождения ребёнка
 * @returns {{months: [], days: [], years: []}}
 */
export function getDateSegments() {
  let days = generateDaysArray(31);
  let months = generateMonthsArray();
  let years = generateYearsArray(20);
  return { days, months, years };
}
