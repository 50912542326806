import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTariffs, selectors } from '@store/tariffsSlice';
import { tariffsConfig } from '@utils/config';

export function SubscribeWrapContainer({ Component, tariffsListHook, ...props }) {
  const tariffs = useSelector(selectors.selectAll);
  const dispatch = useDispatch();

  let tariffsList = tariffs;

  // Хук для предварительного фильтра тарифов
  if (typeof tariffsListHook === 'function') {
    tariffsList = tariffsListHook(tariffsList);
  }

  // Скрываем тарифы для приложений
  tariffsList = tariffsList.filter((tariff) => (tariff.apple_pay_id === '' && tariff.google_pay_id === ''));

  // Устанавливаем, если есть, спец-тариф в начало списка
  const specialTariffIndex = tariffsList.findIndex((tariff) => tariff.id === tariffsConfig.specialTariffId);

  if (specialTariffIndex !== -1) {
    tariffsList.unshift(...tariffsList.splice(specialTariffIndex, 1));
  }

  // Получаем тарифы
  useEffect(() => {
    dispatch(fetchTariffs());
  }, [dispatch]);

  if (tariffsList) {
    return <Component tariffsList={tariffsList} {...props} />
  }

  return null;
}
