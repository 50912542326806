import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getPaymentData, getPaymentHistory, getPromocode } from '@api/payment';

export const fetchPaymentHistory = createAsyncThunk(
  'payment/fetchPaymentHistory',
  async function(_, { rejectWithValue }) {
    try {
      return await getPaymentHistory();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPaymentData = createAsyncThunk(
  'payment/fetchPaymentData',
  async function({ tariffId, recurrent, promocode }, { rejectWithValue }) {
    try {
      return await getPaymentData(tariffId, recurrent, promocode);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPromocode = createAsyncThunk(
  'payment/fetchPromocode',
  async function({ promocode }, { rejectWithValue }) {
    try {
      return await getPromocode(promocode);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const paymentHistoryAdapter = createEntityAdapter({
  selectId: (payment) => payment.id
});

export const selectRecurrentStatus = (store) => store.payment.recurrentStatus;
export const selectData = (store) => store.payment.data;
export const selectPromocode = (store) => store.payment.promocode;
export const selectPromocodeResult = (store) => store.payment.promocodeResult;

const setError = (state, action) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState: paymentHistoryAdapter.getInitialState({
    data: null,
    recurrentStatus: false,
    promocode: '',
    promocodeResult: null,
    status: null,
    error: null
  }),
  reducers: {
    setRecurrentStatus: (state, action) => {
      state.recurrentStatus = action.payload.recurrentStatus;
    },
    setPromocode: (state, action) => {
      state.promocode = action.payload.promocode;
    },
    cleanData: (state) => {
      state.data = null;
    },
    cleanPromocode: (state) => {
      state.promocode = '';
      state.promocodeResult = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        paymentHistoryAdapter.setAll(state, action.payload);
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchPaymentHistory.rejected, setError)
      .addCase(fetchPaymentData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPaymentData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchPaymentData.rejected, setError)
      .addCase(fetchPromocode.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPromocode.fulfilled, (state, action) => {
        state.promocodeResult = action.payload;
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchPromocode.rejected, setError);
  }
});

export const { setRecurrentStatus, setPromocode, cleanData, cleanPromocode } = paymentSlice.actions;

export const selectors = paymentHistoryAdapter.getSelectors((state) => state.payment);

export default paymentSlice.reducer;
