import cn from 'classnames';
import style from './Price.styl';
import { useTariffContext } from '../hooks';

export function Price({ children }) {
  const { theme, promocode } = useTariffContext();

  return (
    <div className={cn(style.price, style[theme || 'default'], { [style.promocode]: promocode })}>
      {
        promocode
        && <div className={style.title}>
          Цена<br />
          по промокоду
        </div>
      }

      <div className={style.value}>
        {children}
        <span className={style.currency}>₽</span>
      </div>
    </div>
  );
}
