import style from './Interests.styl';
import cn from 'classnames';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator } from '@utils/helpers';
import * as images from './images';
import {Link} from "react-router-dom";
import {useAuth} from "../../../../utils/hooks";
import { devicesEnums, useDevice } from '@utils/hooks';
import { appConfig, getEnums } from '@utils/config';
import { Button } from '@components';
export function Interests() {
  const device = useDevice();
  const isLoginIn = useAuth();
  function progressInterpolator(proportion, offset) {
    if (proportion < -1) {
      return { width: 0 };
    } else if (proportion >= -1 && proportion < 0) {
      return { width: `${(1 + proportion) * offset * 100}%` };
    } else if (proportion >= 0) {
      return { width: `${offset * 100}%` };
    }
  }

  function labelInterpolator(proportion) {
    if (proportion < -1) {
      return { opacity: 0 };
    } else if (proportion >= -1 && proportion < 0) {
      return { opacity: (1 + proportion) };
    }
  }

  return (
    <StickyView>
      {(proportion) => (
        <Section>
          <div className={style.interests}>
            <div className={style.content}>
              <Title type="h2" mod={style.title}>
                Узнайте,<br/>
                что интересно<br/>
                вашему ребёнку
              </Title>
              <div className={style.descr}>
                Поможем родителям понять,<br/>
                что же на самом деле нравится<br/>
                их детям.
              </div>
              <img className={style.che} src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" />
            </div>
            <div className={style.graph} style={toTopInterpolator(proportion, 0.8)}>
              <div className={cn(style.progress, style.history)} style={progressInterpolator(proportion, 0.4)}>
                <img src={images.history} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>История</div>
              </div>
              <div className={cn(style.progress, style.space)} style={progressInterpolator(proportion, 0.6)}>
                <img src={images.space} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Космос</div>
              </div>
              <div className={cn(style.progress, style.science)} style={progressInterpolator(proportion, 0.4)}>
                <img src={images.science} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Наука</div>
              </div>
              <div className={cn(style.progress, style.environment)} style={progressInterpolator(proportion, 0.8)}>
                <img src={images.environment} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Окружающий мир</div>
              </div>
              <div className={cn(style.progress, style.nature)} style={progressInterpolator(proportion, 0.7)}>
                <img src={images.nature} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Природа</div>
              </div>
              <div className={cn(style.progress, style.sport)} style={progressInterpolator(proportion, 0.6)}>
                <img src={images.sport} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Спорт</div>
              </div>
              <div className={cn(style.progress, style.creativity)} style={progressInterpolator(proportion, 1)}>
                <img src={images.creativity} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Творчество</div>
              </div>
              <div className={cn(style.progress, style.technology)} style={progressInterpolator(proportion, 0.9)}>
                <img src={images.technology} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Техника</div>
              </div>
              <div  className={cn(style.progress, style.human)} style={progressInterpolator(proportion, 0.4)}>
                <img src={images.human} width={40} height={40} alt="" className={style.icon} />
                <div className={style.label} style={labelInterpolator(proportion)}>Человек</div>
              </div>

            </div>

          </div>
          {
              !isLoginIn
              &&
              <div style={{marginTop:'40px'}}><Button
                  customTag={Link}
                  to={`?popup=${getEnums.popup.demo}&theme=green`}
                  mod={style.button}
                  color="green"
                  size={(device === devicesEnums.pc) ? 'large' : 'small'}
              >
                Узнайте интересы ребенка
              </Button></div>
          }
        </Section>
      )}
    </StickyView>
  );
}
