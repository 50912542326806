import React from 'react';

const BannerContext = React.createContext(undefined);

function BannerProvider({ children, value }) {
  return (
    <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
  );
}

function useBannerContext() {
  const context = React.useContext(BannerContext);

  if (context === undefined) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }

  return context;
}

export { BannerProvider, useBannerContext };
