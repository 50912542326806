import { useEffect } from 'react';

/**
 * Обрабатываем клик вне элемента
 * @param ref Ссылка на элемент
 * @param callback Функция, которая выполняется при клике вне элемента
 */
export function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}
