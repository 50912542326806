import decode from 'jwt-decode';
import Cookies from 'js-cookie';

/**
 * Получаем время жизни токена
 * @param {String} token Токен
 * @returns {Boolean|Date}
 */
export function getTokenExpirationDate(token) {
  if (typeof token !== 'string') {
    return false;
  }

  if (token.split('.').length < 2) {
    return false;
  }

  const decoded = decode(token);

  if (!decoded.exp) {
    return false;
  }

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
}

/**
 * Проверяем если у токена не кончилось время жизни
 * @param {String} token Токен
 * @returns {Boolean}
 */
export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;

  if (date === null) {
    return true;
  }

  return (!(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000));
}

/**
 * Авторизуемся на сайте
 * @param {Object} response Ответ от сервера
 */
export function handleAuthResponse(response) {
  const jwt = response.jwt;
  setToken(jwt);
}

/**
 * Устанавливаем токен в cookie
 * @param {String} token Токен
 * @returns {Boolean}
 */
export function setToken(token) {
  if (!token || token === 'undefined') {
    return false;
  }

  let domain = 'chevostik.ru';

  if (process.env.NODE_ENV === 'development') {
    domain = 'localhost';
  }

  Cookies.set('auth_token', token, { domain, path: '/', expires: 30, secure: true });
}

/**
 * Получаем токен из cookies
 * @returns {String|Boolean}
 */
export function getToken() {
  return Cookies.get('auth_token');
}

/**
 * Выходим из профиля
 */
export function logout() {
  let domain = 'chevostik.ru';

  if (process.env.NODE_ENV === 'development') {
    domain = 'localhost';
  }

  Cookies.remove('auth_token', { domain, path: '/' });
}

/**
 * Проверяем залогинен ли пользователь
 * @returns {Boolean}
 */
export function isLoginIn() {
  const token = getToken();
  return (token && !isTokenExpired(token));
}
