import style from './Select.styl';
import { useFieldContext } from '../hooks';

export function Select({ options }) {
  const { value, onChange } = useFieldContext();

  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={style.select}
    >
      {
        options.map((option) => (
          <option key={option.value} value={option.value}>{option.title}</option>
        ))
      }
    </select>
  );
}
