import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetParameter } from '@utils/hooks';
import { getParams } from '@utils/config';
import { logout } from '@utils/helpers';
import { cleanUser } from '@store/usersSlice';

export function SignOutContainer() {
  const signOut = useGetParameter(getParams.signOut);
  const dispatch = useDispatch();

  if (signOut) {
    logout();
    dispatch(cleanUser());

    return (
      <Navigate to="/" />
    );
  }

  return null;
}
