import style from './Input.styl';
import cn from 'classnames';
import { useFieldContext } from '../hooks';

export function Input({ type }) {
  const { value, disabled, onChange } = useFieldContext();

  return (
    <input
      type={type || 'text'}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={cn(style.input, { [style.right]: (type === 'password') })}
      disabled={disabled}
    />
  );
}
