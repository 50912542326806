import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Children } from '../../components';
import { selectors, fetchChildren } from '@store/childrenSlice';

export function ChildrenContainer() {
  const childrenList = useSelector(selectors.selectAll);
  const dispatch = useDispatch();

  // Получаем детей
  useEffect(() => {
    dispatch(fetchChildren());
  }, [dispatch]);

  return (
    <Children childrenList={childrenList} />
  );
}
