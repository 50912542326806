import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import style from './Home.styl';
import { Demo, Footer } from '@components';
import { SubscribeContainer } from './containers';
import {
  Arts, Compete, Universal, Interests, Lessons,
  MethodologyTitle, Path, Promo, Publications,
  Methodology, Verified, AskQuestions,
} from './components';
import { ScrollProvider } from './components/StickyView/hooks';
import {useAuth} from "../../utils/hooks";
import {ModalWindow} from "./components/ModalWindow/ModalWindow";
import {SecondPromo} from "./components/SecondPromo/SecondPromo";
import {Feedback} from "../../components/FeedbackModal/Feedback";

// TODO: Сделать чтобы адресная строка на мобильных при скролле скрывалась

export function Home({feedback,setFeedback}) {
  const [scrollingElement, setScrollingElement] = useState(null);
  const [showModal,setShowModal] = useState(false);
  const isLoginIn = useAuth();

  function scrollingElRef(ref) {
    setScrollingElement(ref);
  }

  console.log()
  useEffect(() => {
    if(!isLoginIn){
      setTimeout(()=>{
        const query =new URL(window.location);
        if(!query.searchParams.get('popup') && !isLoginIn){
          setShowModal(true)
        }

      },20000)
    }
  }, []);
  return (
    <>
      {showModal && (
          <ModalWindow closeWindow={setShowModal}/>
      )}
      {feedback === true  && (<Feedback setFeedback={setFeedback}/>)}
      <ScrollProvider value={{ scrollingElement }}>

        <div id="scroll-home" className={style.scrollingView} ref={scrollingElRef}>
          <Promo />
          <Lessons />
          <Path />
          <MethodologyTitle />
          <Methodology />
          <Interests />
          <Compete />
          <Verified />
          <Arts />
          <Universal />
          <Publications />
          <SecondPromo/>
          <AskQuestions />
          <SubscribeContainer />
          <Footer />

        </div>
      </ScrollProvider>
      <Outlet />
    </>
  );
}
