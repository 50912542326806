import style from './Section.styl';
import { Head } from './Head/Head';
import { AddButton } from './AddButton/AddButton';
import { Content } from './Content/Content';

export function Section({ id, children }) {
  return (
    <section id={id} className={style.section}>
      { children }
    </section>
  );
}

Section.Head = Head;
Section.AddButton = AddButton;
Section.Content = Content;
