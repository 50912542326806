import { Link } from 'react-router-dom';
import style from './AddButton.styl';
import * as images from './images';

export function AddButton({ to }) {
  return (
    <Link to={to} className={style.button}>
      <img src={images.Union} width={16} height={16} alt="" />
    </Link>
  );
}
