import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ChildEditForm } from '../../components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addChild, selectors, updateChild } from '@store/childrenSlice';
import { appConfig } from '@utils/config';

export function ChildEditContainer() {
  const navigate = useNavigate();
  const { childId } = useParams();
  const childData = useSelector((state) => selectors.selectById(state, childId));
  const dispatch = useDispatch();

  // Данные из формы
  const [name, setName] = useState('');
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [birthday, setBirthday] = useState('');
  const [errors, setErrors] = useState(null);

  let title = 'Новый ребенок';
  let descr = 'Имя и день рождения';
  // let deleteButton = false;

  if (childId) {
    title = 'Имя и день рождения';
    descr = '';
    // deleteButton = true;
  }

  function closePopup() {
    navigate(-1);
  }

  // Получаем данные из формы
  function setFieldsValues(field, value) {
    switch(field) {
      case 'name':
        setName(value);
        break;
      case 'day':
        setDay(value);
        break;
      case 'month':
        setMonth(value);
        break;
      case 'year':
        setYear(value);
        break;
      default:
        break;
    }
  }

  // Отправляем данные на сервер
  async function submit() {
    // Проверяем поля на ошибки
    let nameError;

    if (name.trim().length === 0) {
      nameError = 'Введите имя';
    }

    setErrors({ name: nameError });

    if (nameError) {
      return false;
    }

    const child = { avatar: appConfig.defaultAvatar, name, birthday };

    try {
      if (childId) { // Обновляем данные о ребенке
        await dispatch(updateChild({ id: childId, child })).unwrap();
      } else { // Создаем ребенка
        await dispatch(addChild({ child })).unwrap();
      }

      closePopup();
    } catch(error) {
      setErrors({ name: error });
    }
  }

  // Обновляем данные о редактируемом ребенке
  useEffect(() => {
    if (childData) {
      setName(childData.name);

      if (childData.birthday) {
        const date = new Date(childData.birthday);

        setDay(date.getDate());
        setMonth(date.getMonth());
        setYear(date.getFullYear());
      }
    }
  }, [childData]);

  // Форматируем дату для backend'а
  useEffect(() => {
    setBirthday(`${year}-${Number(month) + 1}-${day}`);
  }, [day, month, year]);

  return (
    <>
      <ChildEditForm
        title={title}
        descr={descr}
        deleteButton={false}
        // deleteButton={deleteButton} TODO: Раскомментировать когда появится API
        name={name}
        day={day}
        month={month}
        year={year}
        errors={errors}
        handleChange={setFieldsValues}
        handleSubmit={submit}
        handleClose={closePopup}
      />
      <Outlet />
    </>
  )
}
