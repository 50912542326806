import style from './Tariff.styl';
import cn from 'classnames';
import { Subtitle } from './Subtitle/Subtitle';
import { Title } from './Title/Title';
import { Price } from './Price/Price';
import { OldPrice } from './OldPrice/OldPrice';
import { Descr } from './Descr/Descr';
import { Comment } from './Comment/Comment';
import { TariffProvider } from '@components/Tariff/hooks';
import * as images from './image';

export function Tariff({ months,theme, promocode, children, style: cssStyle }) {
  return (
    <TariffProvider value={{ theme, promocode }}>

      <section style={{border:months===12 ? '5px solid #0066FF' : '0'}} className={cn(style.tariff, style[theme || 'default'])} >

        {children}
      </section>
    </TariffProvider>
  );
}

Tariff.Subtitle = Subtitle;
Tariff.Title = Title;
Tariff.Price = Price;
Tariff.OldPrice = OldPrice;
Tariff.Descr = Descr;
Tariff.Comment = Comment;
