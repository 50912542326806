import { useEffect } from 'react';
import {useNavigate} from "react-router-dom";

export function Youkassa({ token, parentId, endLoad, backUrl }) {
  const navigate = useNavigate();

  /* eslint-disable */
  useEffect(()  => {
    if (typeof window.YooMoneyCheckoutWidget === 'function') {
      const youkassa = new window.YooMoneyCheckoutWidget({
        confirmation_token: token,
        error_callback: (error) => {
          window.devMode && console.log('Error yookassa', error)
        },
        customization: {
          colors: {
            controlPrimary: '#f77600',
            background: '#FFFEF0',
            border: '#FFFEF0',
            text: '#0F1839'
          }
        }
      });

      youkassa.on('success', () => {
        window.ym(92335881,'reachGoal','pokupka');
        window._tmr.push({ type: 'reachGoal', id: 3319847, goal: 'Pokupka'});
        window.location.replace(backUrl);
        youkassa.destroy();
      });

      youkassa.render(parentId).then(endLoad);
    }
  }, []);
  /* eslint-enable */

  return null;
}
