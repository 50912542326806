import cn from 'classnames';
import style from './Price.styl';
import { useBannerContext } from '../hooks';

export function Price({ children }) {
  const { theme } = useBannerContext();

  return (
    <div className={cn(style.price, style[theme || 'default'])}>
      {children}
      <span className={style.currency}>₽</span>
    </div>
  );
}
