import { useSearchParams } from 'react-router-dom';

/**
 * Получаем значение GET параметра из адресной строки
 * @param {string} name Имя параметра
 * @returns {string}
 */
export function useGetParameter(name) {
  const [query] = useSearchParams();
  return query.get(name);
}
