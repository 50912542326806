import { Title } from '@components';

export function Eula() {
  return (
      <>
      <Title type="h1" mod="page-title">Пользовательское соглашение (публичная оферта)</Title>
      <p>Настоящее Пользовательское соглашение (далее &mdash; &laquo;
        <b>Соглашение</b>&raquo;)
        устанавливает условия и правила использования материалов (включая, но не ограничиваясь:
        аудиовизуальные,
        текстовые, графические, фотоматериалы, изображения и т.д.) Пользователями сети Интернет,
        которые размещены на сайте издательства по адресу&nbsp;
        <a href="https://chevostik.ru/">https://chevostik.ru/</a> и в приложении для App Store и
        Google Play:
      </p>
      <br/>
      <p><b>Публичная оферта</b></p>
      <ul>
        <li>Пользовательское соглашение</li>
        <li>Соглашение</li>
      </ul>
      <p><b>Пользовательское соглашение</b></p>
      <p>Настоящее Пользовательское соглашение (далее &mdash; &laquo;
        <b>Соглашение</b>&raquo;)
        устанавливает условия и правила использования материалов (включая, но не ограничиваясь:
        аудиовизуальные,
        текстовые, графические, фотоматериалы, изображения и т.д.) Пользователями сети Интернет,
        которые размещены
        на сайте издательства по адресу <a href="https://chevostik.ru/">https://chevostik.ru/</a> и в
        приложении для App Store и Google Play:</p>
      <b> Термины и определения</b>
      <p><b>Издательство</b>&nbsp;&ndash; Индивидуальный предприниматель Томисонец Дмитрий
        Алексеевич
        (ОГРН 312774613200381, ИНН 773466961560, адрес местонахождения: 119017, г. Москва, Б.
        Толмачевский пер., д.
        4), зарегистрированный в соответствии с законодательством Российской Федерации.</p>
      <p><b>Сайт</b>&nbsp;&ndash; принадлежащая Издательству совокупность размещенных в
        сети Интернет
        веб-страниц, объединенных единым целевым назначением, дизайном и адресным пространством
        домена&nbsp;<a
            href="https://chevostik.ru/">chevostik.ru</a>&nbsp;. Стартовая страница Сайта размещена по
        адресу&nbsp;<a
            href="https://chevostik.ru/">https://chevostik.ru/</a> и в приложении для App Store и Google
        Play.</p>
      <p><b>Администрация Сайта</b>&nbsp;&ndash; лица, уполномоченные Издательством
        осуществлять от его
        имени управление Сайтом и обрабатывать обращения Пользователей.</p>
      <p><b>Пользователь</b>&nbsp;- дееспособное физическое лицо, совершившее Акцепт
        Соглашения.</p>
      <p><b>Акцепт Соглашения</b>&nbsp;- получение Пользователем доступа к материалам и
        сервисам Сайта
        путем оформления доступа в Личный кабинет, которое означает согласие Пользователя с
        условиями Соглашения.
      </p>
      <p><b>Личный кабинет</b>&nbsp;&ndash; сервис Сайта, представляющий собой
        персональный раздел
        Пользователя на Сайте, при помощи которого можно получить информацию о Заказах,
        осуществлять, Прослушивание,
        Просмотр Объектов контента и др. Доступ к Личному кабинету предоставляется Пользователю
        посредством ввода
        Учетной информации Пользователя.</p>
      <p><b>Учетная информация</b>&nbsp;&ndash; информация о Пользователе, хранящаяся на
        Сайте, в том
        числе содержащая сведения, необходимые для идентификации Пользователя при предоставлении
        доступа в Личный
        кабинет (к такой информации относятся логин, представляющий собой адрес электронной почты
        Пользователя, и
        пароль). Все действия, осуществленные на Сайте с использованием логина и пароля
        Пользователя, считаются
        осуществленными Пользователем. Издательство не несет ответственности за несанкционированное
        использование
        третьими лицами логина и пароля&nbsp;Пользователя.</p>
      <p><b>Счет</b>&nbsp;&ndash; сумма, размер которой определяется на Сайте в процессе
        оформления
        Пользователем Заказа и (или) Электронного заказа, и оплата которой производится посредством
        ресурсов
        Платежного агента. Издательство не гарантирует доступа к ресурсам Платежного агента и не
        несет
        ответственности за их работу.</p>
      <p><b>Платежный агент</b>&nbsp;- любое юридическое лицо, действующее от имени (или
        от своего имени)
        и за счет Издательства в целях получения денежных средств от Пользователя. Перечень
        Платежных агентов
        указывается на Сайте при оформлении Пользователем Заказа. Указанный перечень изменяется
        Издательством путем
        обновления информации на соответствующей странице Сайта. Издательство не несет
        ответственности за работу
        Платежного агента.</p>
      <p><b>Заказ</b>&nbsp;&ndash; запрос Пользователя, оформленный в соответствии с
        условиями настоящего
        Соглашения, на доступ к Объектам контента на определённый Период, который предназначен
        исключительно для
        личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской
        деятельности.
        Доступ предоставляется Пользователю на условиях открытой (неисключительной) лицензии в
        соответствии со ст.
        1286.1 ГК РФ в отношении Объектов контента, оформленный в соответствии с условиями
        настоящего Соглашения.
        Открытая лицензия является договором присоединения. Оплачивая доступ к использованию
        Объектов контента,
        Пользователь совершает акцепт открытой лицензии безоговорочно и в полном объеме. После
        произведения оплаты
        Объекты контента доступны Пользователю для Прослушивания и (или) Просмотра на сайте. Отказ
        от использования
        Объекта контента после получения доступа к нему невозможен.</p>
      <p><b>Электронный заказ -&nbsp; </b>запрос Пользователя, оформленный в соответствии
        с условиями
        настоящего Соглашения, на использование Объектов контента посредством Воспроизведения
        исключительно для
        личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской
        деятельности.
        Доступ предоставляется Пользователю на условиях открытой (неисключительной) лицензии в
        соответствии со ст.
        1286.1 ГК РФ в отношении Объектов контента, оформленный в соответствии с условиями
        настоящего Соглашения.
        Открытая лицензия является договором присоединения. Оплачивая доступ к использованию
        Объектов контента,
        Пользователь совершает акцепт открытой лицензии безоговорочно и в полном объеме. После
        произведения оплаты
        Объекты контента доступны Пользователю для Воспроизведения. Отказ от использования Объекта
        контента после
        получения доступа к нему невозможен.</p>
      <p><b>Период</b> &ndash; срок предоставления доступа к закрытым ресурсам сайта,
        который должен быть
        оплачен надлежащим образом в соответствии с условиями настоящего Соглашения.</p>
      <p><b>Объекты контента</b>&nbsp;&ndash; материалы Сайта, предназначенные
        исключительно для личных,
        семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской
        деятельности, выраженные в
        электронной форме и представляющие собой объекты авторских и смежных
        прав &ndash; произведения, охраняемые
        авторским правом (в том числе литературные произведения, драматические,
        музыкально-драматические
        произведения, музыкальные произведения с текстом или без текста, аудиовизуальные
        произведения, составные
        произведения, произведения изобразительного искусства, фотографические произведения, иные
        объекты авторских
        прав), а также исполнения, постановки, фонограммы и т.д., охраняемые смежными правами. Все
        права на Объекты
        контента защищены. Объекты контента защищаются в соответствии с действующим
        законодательством РФ вне
        зависимости от Акцепта Соглашения Пользователем.</p>
      <p><b>Прослушивание</b>&nbsp;&ndash; предоставленная Пользователям возможность
        прослушивания
        Объектов контента посредством Личного кабинета, с применением технологии, не допускающей
        воспроизведение
        (сохранение или иное копирование) Объекта контента на Устройстве Пользователя.</p>
      <p><b>Просмотр</b>&nbsp;&ndash; предоставленная Пользователям возможность просмотра
        Объектов
        контента посредством Личного кабинета, с применением технологии, не допускающей
        воспроизведение (сохранение
        или иное копирование) Объекта контента на Устройстве Пользователя.</p>
      <p><b>Воспроизведение</b> &ndash; предоставленная Пользователям возможность на
        возмездной основе
        загружать с Сайта в память Устройства Пользователя Объекты контента, размещенные в разделе
        Сайта
        http://shop.chevostik.ru.</p>
      <p><b>Устройство</b> &ndash; техническое средство, посредством которого Пользователь
        осуществляет
        доступ к ресурсам Сайта, включая, но не ограничиваясь &ndash; персональные компьютеры,
        ноутбуки, планшеты,
        мобильные устройства, иные персональные беспроводные устройства.</p>
      <p>Минимальные системные требования к Устройству:</p>
      <p>Операционные системы - Microsoft Windows 10 и более поздние версии, 32/64 бита; Mac OS X
        10.10 или более
        поздние версии. Объем оперативной памяти 8 ГБ (восемь гигабайт).</p>
      <p><b>2. Общие положения</b></p>
      <p>2.1. Настоящее Соглашение в соответствии со статьями 435, 437 Гражданского кодекса
        Российской Федерации
        (далее &mdash; &laquo;ГК РФ&raquo;) является публичной офертой, адресованной Издательством
        любым физическим
        лицам, условия которой изложены ниже и постоянно опубликованы на Сайте.</p>
      <p>2.2. Информация об Объектах контента, представленная на Сайте, включая стоимость
        использования Объектов
        контента, является неотъемлемой частью настоящего Соглашения.</p>
      <p>2.3. В отношении формы заключения настоящего Соглашения применяются нормы ГК РФ,
        регулирующие порядок и
        условия заключения публичного договора (ст. 426 ГК РФ), договора присоединения (ст. 428 ГК
        РФ), оферты (ст.
        435 &ndash; 444 ГК РФ).</p>
      <p>2.4. Настоящее Соглашение устанавливает обязанности Издательства по предоставлению права
        использования
        Объектов контента. Издательство не несет ответственности за работу третьих лиц,
        обеспечивающих доступ
        Пользователю к Сайту (в том числе хостинг-провайдеров, Интернет-провайдеров, регистраторов
        доменов и других
        лиц). Издательство не несет ответственности за стоимость интернет-трафика у провайдера
        Пользователя.
        Издательство не гарантирует бесперебойной работы Сайта. Технические нарушения в работе Сайта
        не являются
        нарушением Издательством условий настоящего Соглашения. Технические нарушения в работе Сайта
        не являются
        отказом Издательства от заключения договора в связи с тем, что они исключают возможность
        предоставить
        Пользователю право использования Объектов контента.</p>
      <p>2.5. Совершая Акцепт настоящего Соглашения, Пользователь соглашается с условиями
        использования материалов и
        (или) сервисов Сайта, а также с условиями открытой (неисключительной) лицензии на Объекты
        контента и
        присоединяется к Соглашению в целом. К отношениям между Пользователем и Издательством
        применяются нормы,
        нормы Закона РФ &laquo;О защите прав потребителей&raquo; от 07.02.1992 г. № 2300-1 и иные
        правовые акты,
        принятые в соответствии с ним, а также нормы ГК РФ, регулирующие порядок и условия
        заключения лицензионного
        договора, представляющего собой открытую (неисключительную) лицензию (ст. 1286.1 ГК РФ), и
        другие положения
        ГК РФ.</p>
      <p>2.6. В случае проведения стимулирующих мероприятий (далее &ndash; &laquo;Акции&raquo;), в
        условиях
        проведения Акций, размещаемых на Сайте, могут быть установлены специальные положения,
        регулирующие порядок
        оформления Заказа и (или) Электронного заказа, взаимодействия Сторон (в том числе в части
        предоставления
        скидок, Объектов контента, доступа к материалам и (или) сервисам Сайта). Условия Акций
        являются неотъемлемой
        частью настоящего Соглашения и подлежат применению к отношениям с Пользователями,
        участвующими в Акциях.
        Любой Пользователь вправе принимать участие в Акциях. Условия участия в Акциях одинаковы для
        всех
        Пользователей. Издательство не оказывает предпочтение одному Пользователю перед другим, не
        предоставляет
        Пользователю уникальную цену использования Объектов контента, иные льготы или преимущества,
        так как
        Пользователь принимает участие в Акциях на равных со всеми Пользователями условиях.</p>
      <p>2.7. Пользователь гарантирует, что он приобретает право использования Объектов контента для
        личных,
        семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской
        деятельности. Пользователь
        гарантирует соблюдение интеллектуальных прав, предусмотренных действующим законодательством
        РФ, вне
        зависимости от действия настоящего Соглашения. Пользователю известно, что любое
        использование результатов
        интеллектуальной деятельности, кроме предусмотренного настоящим Соглашением, без разрешения
        Издательства
        преследуется по закону.</p>
      <p><b>3. Предмет Соглашения</b></p>
      <p>3.1. В соответствии с настоящим Соглашением:</p>
      <p>3.1.1. Издательство обязуется предоставить Пользователю право использования Объектов
        контента, размещенных
        на Сайте, в предусмотренных настоящим Соглашением пределах за вознаграждение, указанное на
        Сайте в
        соответствии с условиями настоящего Соглашения.</p>
      <p>3.2. Территория, на которой Пользователь имеет право использовать Объекты
        контента &ndash; весь мир.</p>
      <p>3.3. Срок действия лицензии:</p>
      <ul>
        <li>весь срок действия исключительного права, в отношении Объектов контента доступных для
          Воспроизведения,
        </li>
        <li>срок оплаченного Периода, в отношении Объектов контента доступных для Просмотра и (или)
          Прослушивания.
        </li>
      </ul>
      <p><b>4.1. Пользователь вправе:</b></p>
      <p>4.1.1. Регистрироваться на Сайте, совершая Акцепт настоящего Соглашения;</p>
      <p>4.1.2. Использовать материалы и (или) сервисы Сайта;</p>
      <p>4.1.3. Оформлять Заказы и (или) Электронные заказы и оплачивать их;</p>
      <p>4.1.4. Принимать участие в Акциях Издательства в порядке, определенном настоящим
        Соглашением и условиями
        Акций, указанными на Сайте в соответствующих разделах;</p>
      <p>4.1.5. Предъявлять претензии Издательству в порядке и сроки, предусмотренные настоящим
        Соглашением,
        направлять вопросы Администрации Сайта;</p>
      <p>4.1.6. Удалять Учетную информацию с целью прекратить действие Соглашения путем направления
        Издательству
        соответствующего уведомления по адресу электронной почты&nbsp;<a
            href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>.</p>
      <p><b>4.2. Пользователь обязан:</b></p>
      <p>4.2.1. Предоставлять достоверную Учетную информацию;</p>
      <p>4.2.2. Хранить в тайне от третьих лиц Учетную информацию (в случае возникновения у
        Пользователя подозрений
        относительно возможности несанкционированного использования Учетной информации третьими
        лицами, Пользователь
        обязуется незамедлительно уведомить об этом Администрацию Сайта, направив электронное письмо
        по адресу
        электронной почты&nbsp;<a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>);</p>
      <p>4.2.3. Соблюдать условия настоящего Соглашения, изменений и дополнений к нему, условия
        Акций,
        опубликованные на Сайте в соответствующих разделах, а также соблюдать действующее
        законодательство РФ в
        отношении результатов интеллектуальной деятельности;</p>
      <p>4.2.4. Отслеживать изменения и дополнения к настоящему Соглашению в порядке,
        предусмотренном настоящим
        Соглашением;</p>
      <p>4.2.5. Знакомиться с правилами и условиями проведения Акции до участия в ней, в случае
        наличия вопросов по
        условиям Акции &ndash; обращаться за разъяснениями к Администрации Сайта;</p>
      <p>4.2.6. Использовать Объекты контента исключительно для личных, семейных, домашних и иных
        нужд, не связанных
        с осуществлением предпринимательской деятельности вне зависимости от действия настоящего
        Соглашения для
        Пользователя;</p>
      <p>4.2.7. Обеспечивать информационную безопасность используемых Устройств во избежание
        хакерских атак,
        мошенничества, обмана и прочих манипуляций на Сайте, которые могут повлечь за собой
        материальные, моральные
        и прочие вредоносные последствия различного типа и степени как для самого Сайта и его
        Пользователей, так и
        для сторонних сайтов и их пользователей или для любой другой стороны, включая Издательство,
        в том числе - в
        случае множественной регистрации Учетной информации одним Пользователем на Сайте;</p>
      <p>4.2.8. Возместить в полном объеме убытки, причиненные Издательству противозаконными
        действиями
        Пользователя, в результате разглашения Пользователем Учетной информации (как личной, так и
        принадлежащей
        третьим лицам), а также в случае использования Устройств, не обеспеченных защитой от
        информационных угроз
        вне зависимости от того, кто причинил указанные убытки &ndash; сам Пользователь или третьи
        лица,
        использовавшие его Устройство;</p>
      <p>4.2.9. Удалить Учетную информацию в случае несогласия с условиями настоящего
        Соглашения.</p>
      <p><b>4.3. Издательство вправе:</b></p>
      <p>4.3.1. Запрашивать у Пользователя Учетную информацию, необходимую для оказания услуг
        Пользователю и
        повышения качества оказываемых услуг, осуществлять ее обработку в соответствии с положениями
        настоящего
        Соглашения;</p>
      <p>4.3.2. Предлагать Пользователю участие в Акциях, в т.ч. с использованием персональных
        данных Пользователя;
      </p>
      <p>4.3.3. Изменять или удалять информацию об Объектах контента (включая описания и цены),
        ассортименте,
        изменять материалы и (или) сервисы Сайта, а также условия Акций и настоящего Соглашения;</p>
      <p>4.3.4. Изменять функционал Личного кабинета по своему усмотрению, в том числе заменять или
        отключать те или
        иные опции (такие как Прослушивание, Просмотр Объектов контента, история, оформление Доступа
        на определенный
        Период и другие опции).</p>
      <p>4.3.5. Привлекать третьих лиц для исполнения обязательств, предусмотренных настоящим
        Соглашением, в целях
        оказания услуг Пользователю, а также заменять их по своему усмотрению, включая Платежного
        агента;</p>
      <p>4.3.6. Предъявлять претензии Пользователю в порядке и сроки, предусмотренные настоящим
        Соглашением;</p>
      <p>4.3.7. Приостановить либо заблокировать доступ Пользователя в Личный кабинет и (или)
        отказать Пользователю
        в использовании Объектов контента и (или) сервисов Сайта (либо их частей &mdash; по
        усмотрению Издательства)
        в одностороннем порядке без предварительного уведомления, а также в одностороннем порядке
        отказаться от
        исполнения настоящего Соглашения без возмещения каких-либо убытков, в случае:</p>
      <ul>
        <li>Нарушения Пользователем какого-либо условия настоящего Соглашения;<br/> выявления
          случаев
          несанкционированного и (или) коммерческого использования полученных посредством Сайта
          Объектов контента,
          которое нарушает права и интересы Издательства и (или) третьих лиц;
        </li>
        <li>Нарушения Пользователем порядка, сроков оплаты и условий получения Объектов контента;
        </li>
        <li>Предоставления неверной информации Пользователем при регистрации на Сайте;</li>
        <li>Уличения Пользователя в мошенничестве, обмане и прочих манипуляциях на Сайте, повлекших
          за собой
          материальные, моральные и прочие вредоносные последствия различного типа и степени как для
          самого Сайта и
          его Пользователей, так и для сторонних сайтов и их пользователей или для любой другой
          стороны, в том
          числе, в случае множественной регистрации Учетной информации одним Пользователем на Сайте
          (блокировка
          возможна также в случае, если Устройство Пользователя было использовано третьими лицами
          для причинения
          указанных выше последствий).
        </li>
      </ul>
      <p><b>4.4. Издательство обязано:</b></p>
      <p>4.4.1. Удалить Учетную информацию Пользователя в случае поступления соответствующего
        заявления от
        Пользователя в порядке и сроки, предусмотренные настоящим Соглашением;</p>
      <p>4.4.2. В течение 10 (Десяти) рабочих дней ответить на претензию Пользователя, направленную
        в соответствии с
        условиями настоящего Соглашения;</p>
      <p>4.4.3. Обеспечить Пользователю возможность получить Доступ к Объектам контента после полной
        оплаты
        Пользователем Заказа и (или) Электронного заказа.</p>
      <p><b>5. Регистрация Пользователя</b></p>
      <p>5.1. При регистрации Пользователь обязуется предоставить достоверный адрес электронной
        почты (логин), а
        также ввести придуманный Пользователем пароль.</p>
      <p>5.2. После регистрации Издательство вправе запросить у Пользователя следующие данные:</p>
      <ul>
        <li>Фамилия;</li>
        <li>Имя;</li>
        <li>Отчество;</li>
        <li>дата рождения;</li>
        <li>пол;</li>
        <li>адрес электронной почты;</li>
        <li>контактный номер телефона;</li>
        <li>адрес для доставки Товаров;</li>
        <li>Фамилия, Имя, Отчество ребенка;</li>
        <li>дата рождения ребенка и родственная связь с ним (мама, бабушка и т.д.);</li>
        <li>пол ребенка;</li>
        <li>отзыв о работе Издательства и его продукции;</li>
        <li>и другую информацию, необходимую Издательству для регистрации и (или) авторизации
          Пользователя на Сайте,
          выполнения Заказов и (или) Электронных заказов и иных обязательств перед Пользователем,
          анализа и оценки
          работы Сайта, анализа покупательских особенностей Пользователя и предоставления ему
          персональных
          рекомендаций, а также определения победителя или получателя скидки в случае проведения
          Издательством
          каких-либо Акций.
        </li>
      </ul>
      <p>5.3. Издательство не несет ответственности за достоверность Учетной информации,
        предоставляемой
        Пользователем при регистрации и после нее.</p>
      <p>5.4. Пользователь обязуется не сообщать третьим лицам логин и пароль от своего Личного
        кабинета и (или)
        иную Учетную информацию. В случае возникновения у Пользователя подозрений относительно
        возможности
        несанкционированного использования его логина и пароля и (или) иной Учетной информации
        третьими лицами,
        Пользователь обязуется незамедлительно уведомить об этом Администрацию Сайта, направив
        электронное письмо по
        адресу электронной почты&nbsp;<a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>. В
        случае
        возникновения у Издательства подозрений относительно использования логина и пароля и (или)
        иной Учетной
        информации Пользователя третьим лицом или вредоносным программным обеспечением, Издательство
        вправе в
        одностороннем порядке изменить пароль и (или) иную Учетную информацию Пользователя и
        уведомить Пользователя
        об этом по адресу электронной почты, указанному Пользователем при регистрации, либо
        заблокировать доступ
        Пользователя в Личный кабинет.</p>
      <p>5.5. В случае предоставления Пользователем своих персональных данных, он подтверждает свое
        согласие на
        сбор, хранение и обработку (систематизацию, накопление, уточнение, использование,
        уничтожение) персональных
        данных, в том числе и в целях продвижения Издательством Объектов контента и услуг. Такое
        согласие не
        устанавливает предельных сроков обработки данных. В соответствии с ч. 2 ст. 5 Федерального
        закона от 27 июля
        2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; обработка персональных данных
        Пользователей
        осуществляется в целях исполнения настоящего Соглашения. Персональная информация
        Пользователей
        распространению не подлежит, кроме случаев, предусмотренных законодательством РФ.
        Пользователь вправе
        полностью или частично отозвать свое согласие на сбор, хранение и обработку своих
        персональных данных в
        любой момент путем направления соответствующего письменного заявления Издательству по адресу
        электронной
        почты&nbsp;<a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>&nbsp;В таком случае
        вся полученная от
        Пользователя информация (в том числе Учетная информация) удаляется из клиентской базы
        Издательства в течение
        30 (Тридцати) рабочих дней с момента получения заявления Издательством, после чего
        Пользователь не сможет
        оформлять Заказы и (или) Электронные заказы посредством Сайта.</p>
      <p>5.6. Во избежание любого рода недоразумений Пользователь подтверждает, что после
        регистрации он согласен с
        получением информационных сообщений от Издательства, в том числе рекламного характера, на
        контактные данные,
        указанные им при регистрации. Пользователь вправе отозвать свое согласие на получение всех
        или определенных
        информационных сообщений, путем направления соответствующего электронного письма по адресу
        электронной
        почты&nbsp;<a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>. Издательство
        обязуется прекратить
        отправку указанных в заявлении информационных сообщений Пользователю в течение 30 (Тридцати)
        рабочих дней с
        момента получения заявления Издательством.</p>
      <p>5.7. Издательство обязуется не разглашать полученную от Пользователя Учетную информацию. Не
        считается
        нарушением указанного обязательства предоставление Издательством Учетной информации
        Пользователя Платежному
        агенту и иным третьим лицам, действующим во исполнение обязательств Издательства перед
        Пользователем, а
        также разглашение информации в соответствии с обоснованными и применимыми требованиями
        действующего
        законодательства РФ. Издательство не несет ответственности за сведения, предоставленные
        Пользователем на
        Сайте в общедоступной форме.</p>
      <p>5.8. Издательство вправе использовать технологию "cookies". "Cookies" не содержат
        конфиденциальную
        информацию и не передаются третьим лицам.</p>
      <p>5.9. Пользователь разрешает Издательству публиковать отзывы или иные комментарии
        Пользователя (в т.ч.
        выдержки из них), информацию об участии Пользователя в каких-либо Акциях Издательства, с
        указанием его
        Фамилии и (или) Имени и (или) Отчества (в том числе - сокращенно), с использованием
        фотографии Пользователя
        и иной Учетной информации.</p>
      <p><b>6. Оформление и сроки выполнения Заказов и (или) Электронных заказов</b></p>
      <p>6.1. Для оформления Заказа и (или) Электронного заказа Пользователю необходимо
        зарегистрироваться на Сайте.
        Без регистрации Пользователь может искать и просматривать информацию Объектах контента.
        Только
        зарегистрированные Пользователи могут:</p>
      <p>- видеть свои персональные настройки с любого Устройства (после ввода логина и пароля);</p>
      <p>- оформлять Заказы и (или) Электронные заказы;</p>
      <p>- получать информацию об Акциях и новинках.</p>
      <p>6.2. При оформлении Заказа и (или) Электронного заказа Пользователь обязательно должен
        указать (за
        исключением логина и пароля) адрес электронной почты для связи с Пользователем.</p>
      <p>6.3. Все информационные материалы о Объектах контента, представленные на Сайте, носят
        справочный характер и
        не могут в полной мере передавать достоверную информацию о свойствах и характеристиках
        Объектов контента. В
        случае возникновения у Пользователя вопросов, касающихся свойств и характеристик Объектов
        контента, перед
        оформлением Заказа и (или) Электронного заказа Пользователь обязан обратиться к
        Администрации Сайта.</p>
      <p>6.4. Поле оформления и оплаты Заказа и (или) Электронного заказа Объекты контента доступны
        Пользователю в
        Личном кабинете для Прослушивания и (или) Просмотра, или Воспроизведения. Отказ от
        приобретения права
        использования Объекта контента после получения доступа к нему невозможен.</p>
      <p>6.5. Издательство вправе аннулировать Заказы в иных случаях, установленных настоящим
        Соглашением.</p>
      <p><b>7. Оплата</b></p>
      <p>7.1. Размер вознаграждения за использование Объектов контента указывается на Сайте. В
        случае неверного
        указания размера вознаграждения заказанных Пользователем Объектов контента, Издательство при
        первой
        возможности информирует об этом Пользователя для подтверждения Электронного заказа по
        исправленной цене либо
        аннулирования такого Заказа и (или) Электронного заказа. При невозможности связаться с
        Пользователем данный
        Заказ и (или) Электронный заказ считается аннулированным. Если Заказ был оплачен,
        Издательство возвращает
        Пользователю оплаченную сумму тем же образом, которым был осуществлен платеж.</p>
      <p>7.2. Размер вознаграждения за использование Объектов контента, указанный на Сайте, может
        быть изменен
        Издательством в одностороннем порядке. Размер вознаграждения за использование заказанных
        Пользователем
        Объектов контента (за исключением случаев неверного указания цены) изменению и возврату не
        подлежит.</p>
      <p>7.3. Оплата Заказа и (или) Электронного заказа производится Пользователем путем авансовых
        платежей с
        помощью Платежных агентов.</p>
      <p>7.3.1. В отношении Объектов контента, доступных только для Прослушивания и (или) Просмотра,
        Пользователь
        оплачивает доступ к использованию таких Объектов контента на определенный Период, в
        соответствии с тарифами,
        указанными на Сайте.</p>
      <p>7.3.2. При оформлении Заказа Пользователю доступна опция автоматического продления Периода,
        в течение
        которого действителен доступ к Объектам контента. При выборе Пользователем данной опции, по
        истечении
        Периода, Издательство посредством Платежного Агента на ежемесячной основе в первый день
        нового Периода или в
        зависимости от условий тарифа списывает авансом денежные средства с банковской карты,
        электронного кошелька
        или иного ресурса, указанного Пользователем.</p>
      <p>7.4. Для оплаты с помощью Платежных агентов Пользователю требуется наличие банковской
        карты, электронного
        кошелька или иного ресурса, соответствующего требованиям, предъявляемым Платежными Агентами.
        Пользователю
        необходимо убедиться, что выбранный способ оплаты обеспечивает перевод достаточного
        количества денежных
        средств и позволяет осуществлять электронные платежи в сети Интернет.</p>
      <p>7.5. Во избежание любого рода недоразумений настоящим Издательство заявляет и предупреждает
        Пользователей о
        том, что Платежные агенты могут взимать сверх стоимости Заказа и (или) Электронного заказа
        любого рода
        комиссии и сборы, ставя при этом условие о принятии Заказа и (или) Электронного заказа лишь
        в случае, если
        такие комиссии и сборы будут оплачены Пользователем. Данные комиссии и сборы не возвращаются
        и не
        возмещаются. Пользователь, совершивший платеж, считается предупрежденным и согласным с этим
        условием.
        Доставка заказанных Товаров оплачивается Пользователем в соответствии с тарифами,
        установленными Службой
        доставки, и рассчитывается отдельно.</p>
      <p>7.6. На момент размещения настоящего Соглашения Платежными агентами, уполномоченными от
        имени Издательства
        получать от Пользователей денежные средства за приобретение права использования Объектов
        контента, являются
        лица, указанные Издательством на странице оплаты Заказа и (или) Электронного заказа. Все
        риски и
        неблагоприятные последствия, связанные с осуществлением оплаты Заказа и (или) Электронного
        Заказа
        посредством иных Платежных агентов, несет Пользователь.</p>
      <p>7.7. Издательство вправе в любое время после опубликования настоящего Соглашения в
        одностороннем порядке
        дополнить список лиц, являющихся Платежными агентами, или исключить любое лицо из списка
        своих Платежных
        агентов, с отражением указанных изменений на Сайте.</p>
      <p>7.8. При оплате права использования Объектов контента Пользователь несет самостоятельную
        ответственность за
        надлежащее следование инструкциям Платежных агентов, устанавливающим порядок и способы
        оплаты, в том числе
        за надлежащее следование Пользователем правилам ввода сообщений и номера коротких текстовых
        сообщений (SMS),
        включая пунктуационный порядок, порядок ввода заглавных и строчных букв, цифр и языка ввода
        и т.д.</p>
      <p>7.9. Издательство вправе в рамках Акций предоставлять Пользователю скидки на использование
        Объектов
        контента. Виды скидок, порядок и условия Акций указаны на Сайте в соответствующих разделах и
        могут быть
        изменены Издательством в одностороннем порядке. Издательство вправе устанавливать скидки в
        целях продвижения
        того либо иного способа оплаты Объектов контента. При этом Издательство может ограничивать
        условия действия
        скидок, ограничивать их предельный размер, а также количество Объектов контента, участвующих
        в Акции. Скидка
        может предоставляться на последующий Заказ также в целях компенсации при оформлении нового
        Заказа.</p>
      <p><b>8. Ответственность и гарантии Сторон</b></p>
      <p>8.1. За невыполнение или ненадлежащее выполнение обязательств по настоящему Соглашению
        Стороны несут
        ответственность в соответствии с действующим законодательством РФ.</p>
      <p>8.2. Стороны понимают, что настоящее Соглашение распространяется только на использование
        материалов и
        сервисов Сайта и не затрагивает использование Пользователем любых иных Интернет-сайтов, в
        том числе тех,
        ссылки на которые публикуются на Сайте.</p>
      <p>8.3. Пользователь гарантирует предоставление Издательству достоверной Учетной информации о
        себе.
        Издательство не несет ответственности за точность и правильность Учетной информации,
        предоставляемой
        Пользователем при регистрации и (или) при оформлении Заказа и (или) Электронного заказа.</p>
      <p>8.4. Пользователь не вправе передавать свой логин и пароль от Личного кабинета третьим
        лицам. Пользователь
        обязуется принимать все разумные меры для сохранения в тайне своего логина и пароля от
        Личного кабинета и
        иной Учетной информации. Издательство не несет ответственности за убытки, причиненные
        Пользователю в
        результате разглашения третьим лицам Учетной информации Пользователя, произошедшего не по
        вине Издательства,
        в частности - за несанкционированное использование третьими лицами логина и пароля
        Пользователя.</p>
      <p>8.5. Пользователь понимает и соглашается с тем, что он несет полную ответственность за
        надлежащее
        использование предоставляемых на Сайте сервисов и материалов, включая (но не ограничиваясь)
        Объекты
        контента. Пользователь обязуется использовать Объекты контента и иные материалы Сайта
        исключительно в личных
        некоммерческих целях.</p>
      <p>8.6. Пользователь понимает и соглашается с тем, что он не вправе независимо от того,
        совершаются ли
        соответствующие действия в целях извлечения прибыли или без такой цели, распространять
        Объекты контента и
        иные материалы Сайта, в том числе продавать и перепродавать доступ к&nbsp; ним, а также
        публично показывать
        или исполнять, транслировать их по радио или телевидению путем сообщения в эфир или по
        кабелю (включая
        ретрансляцию), перерабатывать, переводить, вносить в Объекты контента и в иные материалы
        Сайта любые
        изменения, делать их доступными для любого лица в любое время по его выбору (в том числе
        посредством сети
        Интернет). Любое использование Объектов контента и иных материалов Сайта, кроме прямо
        разрешенного в
        настоящем Соглашении, без предварительного письменного разрешения Издательства
        запрещается.</p>
      <p>8.7. При использовании Сайта, а также Объектов контента и иных материалов Сайта,
        Пользователю запрещается
        совершать следующие действия:</p>
      <p>- использовать Сайт любым способом, который может помешать нормальному функционированию
        Сайта и его
        сервисов;</p>
      <p>- использовать автоматизированные скрипты (программы ЭВМ) для сбора информации и (или)
        взаимодействия с
        Сайтом и его сервисами;</p>
      <p>- пытаться любым способом путем подбора пароля, взлома либо других действий получать доступ
        к Учетной
        информации других Пользователей;</p>
      <p>- размещать на Сайте (в т.ч. загружать), публиковать, передавать, иным образом доводить до
        всеобщего
        сведения посредством Сайта любую информацию, которая не соответствует законодательству
        Российской Федерации;
      </p>
      <p>- использовать ссылки на страницы Сайта или на сам Сайт в спам-рассылках (массовая рассылка
        рекламы без
        согласия на то получателя). В случае нарушения Пользователем указанных условий, Издательство
        вправе
        заблокировать доступ Пользователя к Сайту, удалить его Учетную информацию, привлечь
        Пользователя к
        гражданско-правовой, административной и уголовной ответственности, а также потребовать
        возмещения убытков.
      </p>
      <p>8.8. Объекты контента на Сайте публикуются в том виде, в котором они были предоставлены
        соответствующими
        правообладателями. Издательство не несет ответственность за содержание Объектов контента и
        не гарантирует их
        соответствие требованиям законодательства РФ, ожиданиям отдельных Пользователей и
        применимости для
        конкретных целей. В отношении некоторых Объектов контента и в ознакомительных целях
        Пользователям могут быть
        предоставлены для предварительного ознакомления части (отрывки) Объектов контента.</p>
      <p>8.9. Издательство не несет ответственности за нарушение условий настоящего Соглашения, если
        такое нарушение
        вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая (но не
        ограничиваясь): действия
        органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия,
        отсутствие
        электроэнергии и (или) сбои работы компьютерной сети (нарушение работы линий связи,
        неисправность
        оборудования и т.&thinsp;п.), хакерские атаки, действие компьютерных вирусов, забастовки,
        гражданские
        волнения, беспорядки и иные обстоятельства, которые могут повлиять на выполнение
        Издательством условий
        настоящего Соглашения и неподконтрольные Издательству.</p>
      <p>8.10. Издательство прилагает все возможные усилия для обеспечения нормальной
        работоспособности Сайта,
        однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по
        Соглашению, а также возможные убытки, возникшие в том числе (но не ограничиваясь) в результате:</p>
      <ul>
        <li>
          неправомерных действий третьих лиц, направленных на нарушения информационной
          безопасности или нормального функционирования Сайта;
        </li>
        <li>
          сбоев в работе Сайта, вызванных ошибками в коде, компьютерными вирусами и иными
          посторонними фрагментами кода в программном обеспечении Сайта;
        </li>
        <li>
          отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между
          сервером Пользователя и сервером Сайта;
        </li>
        <li>
          проведения государственными и муниципальными органами, а также иными организациями
          мероприятий в рамках оперативно-розыскных мероприятий;
        </li>
        <li>
          установления государственного регулирования (или регулирования иными организациями)
          хозяйственной деятельности коммерческих организаций в сети Интернет и (или) установления указанными
          субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение Соглашения или его части;
        </li>
        <li>
          других случаев, связанных с действиями или бездействием третьих лиц, направленными на
          ухудшение общей ситуации с использованием сети Интернет и (или) компьютерного оборудования, существовавшей
          на момент заключения Соглашения;
        </li>
        <li>
          выполнения Издательством работ на Сайте, а именно: Издательство имеет право производить
          профилактические работы в программно-аппаратном комплексе Сайта с временным приостановлением работы Сайта
          (по возможности в ночное время с максимально возможным сокращением времени неработоспособности Сайта).
        </li>
      </ul>
      <p>В случае наступления форс-мажорных обстоятельств, а также аварий или сбоев в
        программно-аппаратных
        комплексах третьих лиц, сотрудничающих с Издательством, либо действий или бездействий
        третьих лиц,
        направленных на приостановку или прекращение функционирования Сайта, возможна приостановка
        работы Сайта без
        какого-либо уведомления Пользователя.</p>
      <p>8.11. Издательство не несет ответственности за ущерб, причиненный Пользователю вследствие
        ненадлежащего
        использования Объектов контента.</p>
      <p>8.12. Издательство не несет ответственности за работу Платежного агента и (или) его
        ресурсов, а также за
        содержание и функционирование внешних Интернет-ресурсов, ссылки на которые содержатся на
        Сайте.</p>
      <p>8.13. Издательство вправе переуступать либо каким-либо иным способом передавать свои права
        и обязанности,
        вытекающие из его отношений с Пользователем, третьим лицам.</p>
      <p>8.14. Пользователь обязуется не использовать Объекты контента в предпринимательских
        целях.</p>
      <p><b>9. Заключительные положения</b></p>
      <p>9.1. Настоящее Соглашение и все дополнения к нему являются официальными документами и
        доступны на Сайте.
        Размещение данного Соглашения на Сайте аналогично собственноручной подписи и признается
        подписанием
        Соглашения со стороны Издательства в силу того, что Издательство имеет исключительный доступ
        к содержанию
        Сайта, на котором размещено Соглашение. Соглашение вступает в силу с момента его
        опубликования на Сайте и
        действует до тех пор, пока его действие не будет прекращено соответствующим уведомлением,
        размещенным на
        Сайте, в порядке, предусмотренном настоящим Соглашением.</p>
      <p>9.2. Издательство имеет право в любой момент изменять условия настоящего Соглашения в
        одностороннем порядке
        без предварительного согласования с Пользователем. Измененные условия Соглашения публикуются
        Издательством
        на Сайте не менее чем за 1 (Один) день до их вступления в силу. Пользователь самостоятельно
        отслеживает
        изменения в настоящем Соглашении. При несогласии Пользователя с внесенными изменениями он
        обязан прекратить
        использование материалов и сервисов Сайта путем удаления своей Учетной информации в порядке,
        предусмотренном
        настоящим Соглашением. В противном случае Стороны признают, что их отношения регулируются
        новой редакцией
        Соглашения с даты ее вступления в силу.</p>
      <p>9.3. Издательство имеет право в любой момент в одностороннем порядке без предварительного
        согласования с
        Пользователем изменять материалы и (или) сервисы Сайта, контент (включая цену, описание,
        ассортимент и
        прочее), указанную на Сайте. Указанные изменения вступают силу с момента их опубликования на
        Сайте.</p>
      <p>9.4. В случае возникновения противоречий, текст Соглашения, размещенный на Сайте, будет
        иметь преимущество
        по сравнению с любым иным текстом Соглашения.</p>
      <p>9.5. Пользователь вправе в любой момент прекратить действие Соглашения. Действие Соглашения
        считается
        прекращенным после того, как Пользователь прекратит использование материалов и сервисов
        Сайта путем удаления
        своей Учетной информации в порядке, предусмотренном настоящим Соглашением. Денежные
        средства, уплаченные за
        доступ к Объектам контента, в данном случае возврату не подлежат.</p>
      <p>9.6. К правоотношениям между Пользователем и Издательством применяются положения
        действующего
        законодательства Российской Федерации.</p>
      <p>9.7. Все споры, вытекающие из правоотношений по настоящему Соглашению, разрешаются путем
        переговоров и в
        обязательном претензионном порядке. Претензия направляется той Стороне Соглашения, которой
        допущены
        нарушения его условий, в порядке и сроки, предусмотренные настоящим Соглашением.</p>
      <p>В случае возникновения у Пользователя претензии к Издательству, он обязан в течение 10
        (Десяти) рабочих
        дней с того момента, как у него возникла или могла возникнуть претензия, обратиться к
        Администрации Сайта по
        адресу электронной почты&nbsp;<a
            href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>&nbsp;в письменной
        форме. При этом ответы Администрации Сайта на обращения Пользователя признаются
        направленными в надлежащей
        форме в случае их отправки на электронный адрес Пользователя, указанный им при направлении
        соответствующей
        претензии, либо на электронный адрес Пользователя, указанный им при регистрации на Сайте в
        Учетной
        информации, в течение 10 (Десяти) рабочих дней с момента получения претензии от
        Пользователя.</p>
      <p>Претензия к Пользователю от Издательства признается направленной в надлежащей форме в
        случае ее отправки
        Администрацией Сайта на электронный адрес Пользователя, указанный им при взаимодействии с
        Администрацией
        Сайта, либо на электронный адрес Пользователя, указанный им при регистрации на Сайте в
        Учетной информации. В
        претензии перечисляются допущенные нарушения со ссылкой на соответствующие положения
        Соглашения, а также
        действия, которые должны быть произведены для устранения нарушений. Срок ответа на претензию
        для
        Пользователя составляет 10 (Десять) рабочих дней с момента ее направления Издательством.</p>
      <p>9.8. В случае если Стороны не придут к решению возникших между ними споров в ходе
        переговоров, такие споры
        должны быть переданы на рассмотрение в соответствующий суд Российской Федерации по месту
        нахождения
        Издательства.</p>
      <p>9.9. Признание судом недействительности какого-либо положения настоящего Соглашения не
        влечет за собой
        недействительность остальных положений Соглашения.</p>
      <p>9.10. Согласно письму Министерства связи и массовых коммуникаций Российской Федерации
        №П17-3-31-13282 от
        &laquo;15&raquo; октября 2012 года без знака информационной продукции не допускается
        исключительно оборот
        информационной продукции, способной причинить вред здоровью и (или) развитию детей. Вся
        информация на Сайте
        в соответствии с Федеральным законом от 29.12.2010 N 436-ФЗ (в ред. от 28.07.2012) &laquo;О
        защите детей от
        информации, причиняющей вред их здоровью и развитию&raquo; относится к информации, не
        причиняющей вреда
        здоровью и (или) развитию детей и может распространяться без указания знаков информационной
        продукции.</p>
      <p><b>10. Реквизиты и координаты для связи с Издательством</b></p>
      <p>Индивидуальный предприниматель&nbsp;Томисонец Дмитрий Алексеевич&nbsp;</p>
      <p>Адрес местонахождения:&nbsp;119017, г. Москва, Б. Толмачевский пер., д. 4</p>
      <p>ИНН:&nbsp;773466961560<br/> ОГРНИП:&nbsp;312774613200381<br/> Телефон:&nbsp;(495) 675-53-18, 675-75-93</p>
      <p>E-mail:&nbsp;       <a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a></p>
</>
)
  ;
}
