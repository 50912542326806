import cn from 'classnames';
import style from './Button.styl';

export function Button(props) {
  const {
    children,
    color,
    size,
    fullWidth,
    mod,
    customTag,
    type,
    href,
    to,
    offset,
    smooth,
    onClick
  } = props;

  let Tag = customTag;
  let attributes = {};

  if (!customTag) {
    Tag = 'button';
    attributes.type = 'button';
  }

  return (
    <Tag
      className={
        cn(
          style.button,
          { [style.fullWidth]: fullWidth },
          style[color || 'blue'],
          style[size || 'normal'],
          mod
        )
      }
      type={type}
      href={href}
      to={to}
      smooth={smooth}
      offset={offset}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
}
