import { Group, Layer, Shape, Stage } from 'react-konva';
import style from './Compete.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { degToRad, toTopInterpolator } from '@utils/helpers';
import * as images from './images';
import {Link} from "react-router-dom";
import {useAuth, useDevice,devicesEnums} from "../../../../utils/hooks";
import { appConfig, getEnums } from '@utils/config';
import { Button } from '@components';
export function Compete() {

  const device = useDevice();
  const isLoginIn = useAuth();
  function shapeInterpolator(proportion, offset) {
    const rate = degToRad(200) / 2;

    if (proportion <= -1) {
      return offset;
    } else if (proportion > -1 && proportion <= 1) {
      return (1 + proportion) * rate + offset;
    } else if (proportion > 1) {
      return 2 * rate + offset;
    }
  }

  function imageInterpolator(proportion) {
    const rate = 0.05;

    if (proportion < -1) {
      return { transform: `translateX(0)` };
    } else if (proportion > -1 && proportion <= 1) {
      return { transform: `translateX(${(1 + proportion) * rate * 100}%)` };
    }

    return { transform: `translateX(${2 * rate * 100}%)` };
  }

  return (
    <StickyView height={1200}>
      {(proportion) => (
        <Section>
          <div className={style.compete} style={toTopInterpolator(proportion, 0.3)}>
            <img src={images.che} srcSet={`${images.cheRetina} 2x`} style={imageInterpolator(proportion)} alt="" className={style.che} />
            <img src={images.star} srcSet={`${images.starRetina} 2x`} style={imageInterpolator(proportion)} alt="" className={style.star} />
            <div className={style.graph}>
              <img src={images.starIcon} width={48} height={46} style={imageInterpolator(proportion)} alt="" className={style.starIcon} />
              <img src={images.crownIcon} width={50} height={39} style={imageInterpolator(proportion)} alt="" className={style.crownIcon} />
              <img src={images.cupIcon} width={40} height={45} style={imageInterpolator(proportion)} alt="" className={style.cupIcon} />
              <Stage width={688} height={688}>
                <Layer>
                  <Group x={688} y={0} rotation={90}>
                    <Shape
                      width={688}
                      height={688}
                      sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.arc(
                          344,
                          344,
                          310,
                          0,
                          shapeInterpolator(proportion, 1.2),
                          false
                        );
                        context.strokeStyle = '#FFC700';
                        context.lineWidth = 68;
                        context.lineCap = 'round';
                        context.stroke();
                        context.closePath();
                        context.fillStrokeShape(shape);
                      }}
                    />
                    <Shape
                      width={488}
                      height={488}
                      sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.arc(
                          344,
                          344,
                          230,
                          0,
                          shapeInterpolator(proportion, 1.5),
                          false
                        );
                        context.strokeStyle = '#C900EA';
                        context.lineWidth = 68;
                        context.lineCap = 'round';
                        context.stroke();
                        context.closePath();
                        context.fillStrokeShape(shape);
                      }}
                    />
                    <Shape
                      width={288}
                      height={288}
                      sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.arc(
                          344,
                          344,
                          150,
                          0,
                          shapeInterpolator(proportion, 0),
                          false
                        );
                        context.strokeStyle = '#00EA96';
                        context.lineWidth = 68;
                        context.lineCap = 'round';
                        context.stroke();
                        context.closePath();
                        context.fillStrokeShape(shape);
                      }}
                    />
                  </Group>
                </Layer>
              </Stage>
            </div>
            <div className={style.content} style={toTopInterpolator(proportion, 0.8)}>
              <Title type="h2" mod={style.title}>Соревнуйтесь</Title>
              <div className={style.descr}>
                Читайте. Слушайте.<br/>
                Выполняйте задания и играйте.<br/>
                Следите за рейтингом.
              </div>
              {
                  !isLoginIn
                  && <div style={{marginTop:'10px'}}><Button
                      customTag={Link}
                      to={`?popup=${getEnums.popup.signUp}&theme=green`}
                      mod={style.button}
                      color="green"
                      size={'small'}
                  >
                    Попробовать сейчас
                  </Button></div>
              }
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
