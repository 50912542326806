import { useEffect, useState } from 'react';

export const devicesEnums = {
  pc: 'pc',
  tablet: 'tablet'
};

/**
 * Определяет тип устройства
 * @returns {string<'tablet'|'pc'>}
 */
export function useDevice() {
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);

  function handleChange(e) {
    setMatches(e.matches)
  }

  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change', handleChange);
  }, [matches]);

  if (!matches) {
    return devicesEnums.tablet;
  }

  return devicesEnums.pc;
}
