export const appConfig = {
  apiHost: window.location.host.includes('new') ? 'https://stage.chevostik.ru' : 'https://chevostik.ru',
  gameHost: window.location.host.includes('new') ? 'https://stage.chevostik.ru' : 'https://game.chevostik.ru',
  playMarketLink: 'https://play.google.com/store/apps/details?id=ru.chevostik.chevostik&gl=ru&hl=ru&pli=1',
  appstoreLink: 'https://apps.apple.com/us/app/%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D0%B5-%D0%B8%D0%B3%D1%80%D1%8B-%D0%B3%D0%BE%D0%BB%D0%BE%D0%B2%D0%BE%D0%BB%D0%BE%D0%BC%D0%BA%D0%B8-3/id1609206459',
  rustoreLink: 'https://apps.rustore.ru/app/ru.chevostik.chevostik',
  instagramLink: 'https://www.instagram.com/chevostik_ru',
  vkLink: 'https://vk.com/chevostik_ru',
  fbLink: 'https://www.facebook.com/chevostik',
  youtubeLink: 'https://www.youtube.com/channel/UCxky9inTuSIvjl648YqWPnA',
  telegramLink: 'https://t.me/chevostik',
  defaultAvatar: '/img/avatars/default_avatar.png',
  subscribeActive: ['on']
};
