import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DeleteChild } from '../../components';
import { removeChild } from '@store/childrenSlice';

export function DeleteChildContainer() {
  const { childId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function deleteChild() {
    dispatch(removeChild({ id: childId }));
    navigate(-2);
  }

  return (
    <DeleteChild
      handleDelete={deleteChild}
      handleClose={() => navigate(-1)}
    />
  );
}
