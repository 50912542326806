import {memo, useCallback, useEffect, useRef, useState} from 'react';
import style from './Path.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { devicesEnums, useDevice } from '@utils/hooks';
import * as images from './images';

const PatchContent = memo(() => {
  return (
    <>
      <img className={style.che} src={images.che} srcSet={`${images.cheRetina} 2x`} alt=""/>
      <div className={style.content}>
        <div className={style.descr}>
          Проходите уроки как удобно -
          в произвольном порядке
          или используйте
        </div>
        <Title type="h2">Маршруты</Title>
      </div>
    </>
  )
});

export function Path() {
  const device = useDevice();
  const [scrollWidth, setScrollWidth] = useState(0);
  const [runWidth, setRunWidth] = useState(0);
  const [pathLoaded, setPathLoaded] = useState(false);
  const scrollRef = useRef();
  const runRef = useRef();

  // TODO: Привести в порядок функцию интерполяции
  const scrollInterpolator = useCallback((proportion) => {
    const iconRadius = device === devicesEnums.pc ? 90 : 45;
    const offset = (runWidth - iconRadius) / 2;
    const startTranslate = offset / scrollWidth * 100;
    const rate = 1 + (offset - runWidth) / scrollWidth;

    if (proportion >= -0.5 && proportion <= 1) {
      return { transform: `translateX(${startTranslate - proportion * rate * 100}%)` };
    } else if (proportion > 1) {
      return { transform: `translateX(${startTranslate - rate * 100}%)` };
    }

    return { transform: `translateX(${startTranslate}%)` };
  }, [device, runWidth, scrollWidth]);

  useEffect(() => {
    if (runRef.current) {
     // setRunWidth(runRef.current.offsetWidth);
    }
  }, [runRef]);

  useEffect(() => {
    if (scrollRef.current) {
    //  setScrollWidth(scrollRef.current.offsetWidth);
    }
  }, [pathLoaded]);

  if (device === devicesEnums.tablet) {
    return (
      <Section fullHeight={false}>
        <div className={style.path}>
          <PatchContent />
          <div className={style.run}>
            <div className={style.scroll} style={{ transform: 'translateX(calc(100vw - 100% - 40px))' }}>
              <img
                  src={images.path}
                  srcSet={`${images.pathRetina} 2x`}
                  alt=""
                  className={style.road}
                  onLoad={() => setPathLoaded(true)}
              />
              <div className={style.runDescr}>
                Все маршруты построены так,
                чтобы раскрыть блок знаний от основ до деталей,
                от истории до наших дней. Например, изучить литературу
                от Древней Греции до Пушкина.
              </div>
            </div>
          </div>
        </div>
      </Section>
    );
  }

  return (
    <StickyView >
      {(proportion) => (
        <Section>
          <div className={style.path}>
            <PatchContent />
            <div ref={runRef} className={style.run}>
              <div ref={scrollRef} className={style.scroll} style={scrollInterpolator(proportion)}>
                <img
                  src={images.path}
                  srcSet={`${images.pathRetina} 2x`}
                  alt=""
                  className={style.road}
                  onLoad={() => setPathLoaded(true)}
                />
                <div className={style.runDescr}>
                  Все маршруты построены так,
                  чтобы раскрыть блок знаний от основ до деталей,
                  от истории до наших дней. Например, изучить литературу
                  от Древней Греции до Пушкина.
                </div>
              </div>
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
