import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getTariffs } from '@api/tariffs';

export const fetchTariffs = createAsyncThunk(
'tariffs/fetchTariffs',
async function(_, { rejectWithValue }) {
  try {
    return await getTariffs();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const tariffsAdapter = createEntityAdapter({
  selectId: (tariff) => tariff.id
})

const setError = (state, action) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState: tariffsAdapter.getInitialState({
    currentTariff: null,
    status: null,
    error: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTariffs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTariffs.fulfilled, (state, action) => {
        tariffsAdapter.setAll(state, action.payload);
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchTariffs.rejected, setError)
  }
});

export const selectors = tariffsAdapter.getSelectors((state) => state.tariffs);

export default tariffsSlice.reducer;
