import { Link } from 'react-router-dom';
import style from './TariffsList.styl';
import { Button, Tariff } from '@components';
import { appConfig, tariffsConfig, tariffsThemes } from '@utils/config';
import { dateFormat, daysToSubscriptionDue, arrangePromocodeResult } from '@utils/helpers';
import * as images from './image';

export function TariffsList(props) {
  const {
    tariffs,
    promocode,
    subscription,
    interpolator,
  } = props;
  const tariffsSales = arrangePromocodeResult(promocode)

  return (
    <>

      {
        tariffs.map((tariff, index) => (

          <Tariff
            months={tariff.months_number}
            key={tariff.id}
            theme={tariffsThemes[tariff.id]}
            promocode={tariffsSales[tariff.id]}
            style={interpolator?.(index)}
          >

            { (tariffsConfig.specialTariffId === tariff.id) && <Tariff.Subtitle>Супер цена!</Tariff.Subtitle> }
            <div style={{height:'27px', lineHeight:'54px',alignItems:'center', justifyContent:'center'}}>
            <Tariff.Title>

              {(tariffsConfig.specialTariffId === tariff.id) && ' всего\u00A0за' }
            </Tariff.Title>
              {tariff.title} {tariff.months_number === 12 &&(
                <img className={style.image} src={images.fire}/>
            )}
            </div>
            <div style={{display:'flex'}}>
            <Tariff.Price>
              { (tariffsSales[tariff.id]?.price >= 0) ? tariffsSales[tariff.id].price : tariff.price }
            </Tariff.Price>

            { (tariff.old_price > 0) && <Tariff.OldPrice>{tariff.old_price}</Tariff.OldPrice> }
            </div>
            {(tariff.old_price-tariff.price > 0) && (<div className={style.profit}>Выгода {tariff.old_price-tariff.price}р</div>)}
            <Tariff.Descr>{tariff.description}</Tariff.Descr>

            {
              // Если спец-тариф добавляем комментарий
              (subscription && tariffsConfig.specialTariffId === tariff.id)
              && <Tariff.Comment>
                Предложение действует <b>{daysToSubscriptionDue(subscription.due_date).toLowerCase()}</b> до {dateFormat(subscription.due_date)}
              </Tariff.Comment>
            }

            {
              subscription
                ? <Button
                  customTag={Link}
                  to={`payment/${tariff.id}`}
                  mod={style.button}
                  size="small"
                  fullWidth={true}
                >
                  { appConfig.subscribeActive.includes(subscription.status) ? 'Сменить тариф' : 'Подписаться' }
                </Button>
                : <Button
                  customTag={Link}
                  to="?popup=sign-in"
                  size="small"
                  fullWidth={true}
                >
                  Подписаться
                </Button>
            }

          </Tariff>
        ))
      }
    </>
  );
}
