/**
 * Формируем массив с ID тарифов
 * в качестве ключа и новыми ценами на них
 * @param {array} promocodeResult Результат применения промокода
 * @returns {boolean|array}
 */
export function arrangePromocodeResult(promocodeResult) {
  if (!promocodeResult) {
    return false;
  }

  let result = [];

  promocodeResult.tariffs?.forEach((tariff) => {
    result[tariff.id] = tariff;
  })

  return result;
}
