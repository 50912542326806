import { api } from '@utils/helpers';

/**
 * @typedef {Object} Tariff
 */

/**
 * Получаем список тарифов
 * @returns {Promise<[Tariff]>}
 */
export async function getTariffs() {
  try {
    const response = await api.get('/tariffs');
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}
