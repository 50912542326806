import cn from 'classnames';
import style from './Subtitle.styl';
import { useBannerContext } from '../hooks';

export function Subtitle({ children }) {
  const { theme } = useBannerContext();

  return (
    <div  className={cn(style.subtitle, style[theme || 'default'])}>{children}</div>
  );
}
