import React from 'react';

const TariffContext = React.createContext(undefined);

function TariffProvider({ children, value }) {
  return (
    <TariffContext.Provider value={value}>{children}</TariffContext.Provider>
  );
}

function useTariffContext() {
  const context = React.useContext(TariffContext);

  if (context === undefined) {
    throw new Error('useTariffContext must be used within a TariffProvider');
  }

  return context;
}

export { TariffProvider, useTariffContext };
