import style from './Message.styl';
import cn from 'classnames';
import { Title } from './Title/Title';
import { Descr } from './Descr/Descr';

export function Message({ type, children }) {
  return (
    <div className={cn(style.message, style[type || 'info'])}>
      <div className={style.container}>
        {children}
      </div>
    </div>
  )
}

Message.Title = Title;
Message.Descr = Descr;
