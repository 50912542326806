import { useSelector } from 'react-redux';
import { User } from '../../components';
import { selectUser } from '@store/usersSlice';

export function UserContainer() {
  const user = useSelector(selectUser);

  return (
    <User user={user} />
  );
}
