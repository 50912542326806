import { Outlet } from 'react-router-dom';
import style from './Profile.styl';
import {
  SpecialBannerContainer,
  ChildrenContainer,
  SubscribeContainer,
  UserContainer,
} from './containers';
import { PageHead } from './components';
import {QuestContainer} from "./containers/QuestContainer/QuestContainer";

export function Profile() {
  return (
    <>

      <div className={style.profile}>
        <PageHead />
        <SpecialBannerContainer />
        <UserContainer />
        <ChildrenContainer />
        <QuestContainer/>
        <SubscribeContainer />
      </div>
      <Outlet />
    </>
  );
}
