import style from './AskQuestions.styl';
import { Title } from '@components';
import { Section } from '../../components';
import {useState} from "react";
import * as images from './images';

export function AskQuestions() {
    const [blockOne,setBlockOne] = useState(false)
    const [blockTwo,setBlockTwo] = useState(false)
    const [blockThree,setBlockThree] = useState(false)
  return (
      <Section>
          <Title type="h2">
              Вопрос-ответ
          </Title>
          <div onClick={() => setBlockOne(!blockOne)} className={style.block}>
              <div>
                  <div style={{fontWeight: '600', fontSize: '24px'}}>Если в семье несколько детей, необходимо ли мне
                      приобретать отдельные подписки на каждого из них?
                  </div>
                  {blockOne && (<div style={{marginTop: '10px', fontWeight: '300', fontSize: '22px'}}>Нет, одна подписка
                      распространяется на всех ваших детей (до 4-х)</div>)}
              </div>
              <div>
                  {blockOne ? <img src={images.minus}/> : <img src={images.plus}/>
                  }
              </div>
          </div>
          <div onClick={() => setBlockTwo(!blockTwo)} className={style.block}>
              <div>
                  <div style={{fontWeight: '600', fontSize: '24px'}}>Может ли ребенок самостоятельно заниматься?
                  </div>
                  {blockTwo && (<div style={{marginTop: '10px', fontWeight: '300', fontSize: '22px'}}>Да, после регистрации вы можете предоставить ребенку доступ к приложению, которое обеспечивает безопасную среду без внешних ссылок, позволяя ему увлекательно проходить уроки.</div>)}
              </div>
              <div>
                  {blockTwo ? <img src={images.minus}/> : <img src={images.plus}/>
                  }
              </div>
          </div>
          <div onClick={() => setBlockThree(!blockThree)} className={style.block}>
              <div>
                  <div style={{fontWeight: '600', fontSize: '24px'}}> Что включают в себя уроки?
                  </div>
                  {blockThree && (<div style={{marginTop: '10px', fontWeight: '300', fontSize: '22px'}}>Внутри уроков содержатся только проверенные материалы, увлекательные видео- и аудио-факты, озвученные профессионалами, которые наглядно иллюстрируют учебный материал.</div>)}
              </div>
              <div>
                  {blockThree ? <img src={images.minus}/> : <img src={images.plus}/>
                  }
              </div>
          </div>
      </Section>
  );
}
