import React from 'react';

const RowContext = React.createContext(undefined);

function RowProvider({ children, value }) {
  return (
    <RowContext.Provider value={value}>{children}</RowContext.Provider>
  );
}

function useRowContext() {
  const context = React.useContext(RowContext);

  if (context === undefined) {
    throw new Error('useRowContext must be used within a RowProvider');
  }

  return context;
}

export { RowProvider, useRowContext };
