import { Link } from 'react-router-dom';
import style from './ChildEditForm.styl';
import { Button, Field, Popup } from '@components';
import { getDateSegments } from '@utils/helpers';

export function ChildEditForm(props) {
  const {
    title,
    descr,
    deleteButton,
    name,
    day,
    month,
    year,
    errors,
    handleChange,
    handleSubmit,
    handleDelete,
    handleClose
  } = props;

  const { days, months, years } = getDateSegments();
  return (
    <Popup size="small" onClose={handleClose}>
      <Popup.Title>{title}</Popup.Title>
      <Popup.Descr>{descr}</Popup.Descr>
      <Popup.Content>
        <div className={style.childEdit}>
          <Field
            mod={style.name}
            value={name}
            error={errors?.name}
            onChange={(value) => handleChange('name', value)}
          >
            <Field.Label>Имя</Field.Label>
            <Field.Input />
          </Field>
          <Field mod={style.day} value={day} onChange={(value) => handleChange('day', value)}>
            <Field.Label>Дата</Field.Label>
            <Field.Select options={days} />
          </Field>
          <Field mod={style.month} value={month} onChange={(value) => handleChange('month', value)}>
            <Field.Label>Месяц</Field.Label>
            <Field.Select options={months} />
          </Field>
          <Field mod={style.year} value={year} onChange={(value) => handleChange('year', value)}>
            <Field.Label>Год</Field.Label>
            <Field.Select options={years} />
          </Field>
          <Button mod={style.button} onClick={handleSubmit}>Сохранить</Button>
          {
            deleteButton
            && <div className={style.deleteWrap}>
              <Link to="confirm/" className={style.delete} onClick={handleDelete}>Удалить ребенка</Link>
            </div>
          }
        </div>
      </Popup.Content>
    </Popup>
  );
}
