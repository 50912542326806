import cn from 'classnames';
import style from './Field.styl';
import { ErrorMessage } from '@components';
import { Label } from './Label/Label';
import { Input } from './Input/Input';
import { Select } from './Select/Select';
import { CleanButton } from './CleanButton/CleanButton';
import { FieldProvider } from './hooks';

export function Field(props) {
  const {
    mod,
    value,
    error,
    disabled,
    onChange,
    onClean,
    children
  } = props;

  return (
    <FieldProvider value={{ value, disabled, onChange, onClean }}>
      <label className={cn(style.field, { [style.error]: error }, mod)}>
        {children}

        {
          error && <ErrorMessage mod={style.errorMessage}>{error}</ErrorMessage>
        }

      </label>
    </FieldProvider>
  );
}

Field.Label = Label;
Field.Input = Input;
Field.Select = Select;
Field.CleanButton = CleanButton;
