export const getParams = {
  popup: 'popup',
  theme: 'theme',
  signOut: 'sign-out'
};

export const getEnums = {
  popup: {
    signIn: 'sign-in',
    signUp: 'sign-up',
    forgotPassword: 'forgot-password',
    demo: 'demo'
  }
};
