import { Link } from 'react-router-dom';
import style from './Footer.styl';
import * as images from './images';
import { appConfig } from '@utils/config';

export function Footer() {
  return (
    <footer className={style.footer}>
      <div className={style.socials}>
        <a href={appConfig.instagramLink} target="_blank" rel="noreferrer">
          <img src={images.instagramIcon} width={26} height={26} alt=""/>
        </a>
        <a href={appConfig.vkLink} target="_blank" rel="noreferrer">
          <img src={images.vkIcon} width={26} height={26} alt=""/>
        </a>
        <a href={appConfig.fbLink} target="_blank" rel="noreferrer">
          <img src={images.fbIcon} width={12} height={24} alt=""/>
        </a>
        <a href={appConfig.youtubeLink} target="_blank" rel="noreferrer">
          <img src={images.youtubeIcon} width={37} height={26} alt=""/>
        </a>
        <a href={appConfig.telegramLink} target="_blank" rel="noreferrer">
          <img src={images.telegramIcon} width={30} height={24} alt=""/>
        </a>
      </div>
      <div className={style.nav}>
        <Link to="privacypolicy/" className={style.navLink}>Правила использования</Link>
        <Link to="eula/" className={style.navLink}>Пользовательское соглашение</Link>
      </div>
      <div className={style.copyright}>© Приключения Чевостика, {new Date().getFullYear()}.</div>
      <div className={style.ageRating}>
        <div className={style.ageRatingValue}>3+</div>
      </div>
    </footer>
  );
}
