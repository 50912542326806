import style from './Methodology.styl';
import { Section, StickyView } from '../../components';
import { Step } from './Step/Step';
import { toTopInterpolator } from '@utils/helpers';
import { devicesEnums, useDevice } from '@utils/hooks';
import * as images from './images';
import {Link} from "react-router-dom";
import {useAuth} from "../../../../utils/hooks";
import { appConfig, getEnums } from '@utils/config';
import { Button } from '@components';

export function Methodology() {
  const device = useDevice();
  const isLoginIn = useAuth();
  return (
    <StickyView autoHeight={device === devicesEnums.tablet}>
      {(proportion) => (
        <Section mod={style.sectionMethodology}>
          <div style={{marginBottom:'20px'}} className={style.methodology}>
            <div className={style.step}>
              <Step
                proportion={proportion}
                stepName="Раз"
                image={images.che1}
                imageRetina={images.cheRetina1}
                title="Узнавай"
              >
                Увлекательные путешествия на фантастическом аппарате, переносящем сквозь
                пространство и время. К нашим историям захочется возвращаться снова, это даст
                глубокое погружение в тему и лучшее закрепление знаний.
              </Step>
            </div>
            <div className={style.step} style={toTopInterpolator(proportion, 0.2)}>
              <Step
                proportion={proportion}
                stepName="Два"
                image={images.che2}
                imageRetina={images.cheRetina2}
                title="Проверяй"
              >
                Блиц-тест научит правильно задавать вопросы и находить ответы. Дети узнают
                мир через игру намного быстрее и эффективнее. Если верно ответить на все
                вопросы, то откроется доступ к логической игре.
              </Step>
            </div>
            <div className={style.step} style={toTopInterpolator(proportion, 0.6)}>
              <Step
                proportion={proportion}
                stepName="Три!"
                image={images.che3}
                imageRetina={images.cheRetina3}
                title="Играй"
              >
                Логическая игра по каждому приключению помогает закрепить полученную
                информацию и сбалансировать время ребенка между получением знаний и отдыхом
                для максимально эффективного процесса обучения.
              </Step>
            </div>
          </div>
          {
              !isLoginIn
              && <Button
                  customTag={Link}
                  to={`?popup=${getEnums.popup.signUp}&theme=green`}
                  mod={style.button}
                  color="green"
                  size={(device === devicesEnums.pc) ? 'large' : 'small'}
              >
                Попробовать сейчас
              </Button>
          }
        </Section>
      )}
    </StickyView>
  );
}
