import { useEffect, useRef, useState } from 'react';
import style from './StickyView.styl';
import { useScrollContext } from './hooks';

export function StickyView(props) {
    const { height, children, autoHeight } = props;
    const elRef = useRef();
    const { scrollingElement } = useScrollContext();
    const [proportion, setProportion] = useState(null);

    function handleScroll(container) {
        const containerRect = container.getBoundingClientRect();
        const selfRect = elRef.current.getBoundingClientRect();
        const offTop = containerRect.y - selfRect.y;

        if (containerRect.height < selfRect.height) {
            const viewHeight = selfRect.height - containerRect.height;
            setProportion(offTop / viewHeight);
        } else {
            const viewHeight = containerRect.height;
            const result = offTop < 0 ? offTop / viewHeight : offTop / viewHeight + 1;
            setProportion(result);
        }
    }

    useEffect(() => {
        if (scrollingElement) {
            scrollingElement.addEventListener('scroll', (e) => handleScroll(e.target));
            handleScroll(scrollingElement);
        }
        return () => scrollingElement?.removeEventListener('scroll', handleScroll);
    }, [scrollingElement]);

    return (
        <div className={style.stickyView} ref={elRef} style={{ height }}>
            <div
                className={style.stickyViewSticky}
                style={{ height: !autoHeight ? scrollingElement?.clientHeight : 'auto' }}
            >
                {children(proportion)}
            </div>
        </div>
    );
}
