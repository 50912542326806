import cn from 'classnames';
import style from './Actions.styl';
import { useBannerContext } from '../hooks';

export function Actions({ children }) {
  const { theme } = useBannerContext();

  return (
    <div className={cn(style.actions, style[theme || 'default'])}>
      {children}
    </div>
  );
}
