import { Outlet } from 'react-router-dom';
import style from './LayoutFullWidth.styl';
import { HeaderContainer, PopupsContainer, SignOutContainer } from '@containers';
import { Footer } from '@components';

export function LayoutFullWidth({setFeedback}) {
  return (
    <>
      <HeaderContainer setFeedback={setFeedback}/>
      <div className={style.page}>
        <div className={style.container}>
          <Outlet />
        </div>
        <Footer />
      </div>
      <PopupsContainer />
      <SignOutContainer />
    </>
  );
}
