import style from './Verified.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator } from '@utils/helpers';
import * as images from './images';
import { appConfig } from '@utils/config';

export function Verified() {
  return (
    <StickyView>
      {(proportion) => (
        <Section>
          <div className={style.verified}>
            <div className={style.content} style={toTopInterpolator(proportion, 0.6)}>
              <Title type="h2" mod={style.title}>
                Только<br/>
                проверенные<br/>
                факты
              </Title>
              <div className={style.descr}>
                Научные редакторы, учёные,<br/>
                специалисты в областях<br/>
                и психологи досконально проверили<br/>
                материалы, на которых<br/>
                построены наши уроки.
              </div>
              <a href={`${appConfig.gameHost}/about`} className={style.link}>Подробнее</a>
            </div>
            <div className={style.cheWrap}>
              <img src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" className={style.che}/>
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
