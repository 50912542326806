import style from './Subscribe.styl';
import {Title, Promocode, TariffsList} from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator } from '@utils/helpers';
import { devicesEnums, useDevice } from '@utils/hooks';
import * as images from './images';
import {useAuth} from "../../../../utils/hooks";

export function Subscribe(props) {
  const {
    tariffsList,
    subscription,
    promocode,
    promocodeResult,
    handlePromocodeChange,
    handlePromocodeApply,
    handlePromocodeClean,
  } = props;

  const device = useDevice();
  const isLoginIn = useAuth();
  return (
    <StickyView autoHeight={device === devicesEnums.tablet}>
      {(proportion) => (
        <Section id="subscribe">
          <div className={style.subscribe}>
            <Title type="h2" mod={style.title}>Выберите план подписки</Title>
            <div className={style.list}>
              { tariffsList && <TariffsList
                tariffs={tariffsList}
                promocode={promocodeResult}
                subscription={subscription}
                interpolator={(index) => toTopInterpolator(proportion, 0.2 * index)}
              /> }
            </div>
            <div className={style.image}>
              <img src={images.logo} srcSet={`${images.logoRetina} 2x`} alt="" className={style.logo}/>
            </div>
            {isLoginIn &&(
                <>
                 {/* <div className={style.recurrent}>
                    <Checkbox checked={recurrent} handleChange={handleRecurrentChange}>
                      Автоматическое продление подписки
                    </Checkbox>
                  </div>*/}
                  <Promocode
                      promocode={promocode}
                      promocodeResult={promocodeResult}
                      handleChange={handlePromocodeChange}
                      handleApply={handlePromocodeApply}
                      handleClean={handlePromocodeClean}
                  />
                </>
            )}

          </div>
        </Section>
      )}
    </StickyView>
  );
}
