import { Link } from 'react-router-dom';
import style from './Auth.styl';
import { Button, ErrorMessage, Field, Popup } from '@components';
import { getEnums } from '@utils/config';
import * as images from './images';

export function Auth(props) {
  const {
    open,
    email,
    password,
    errors,
    handleChange,
    handleSubmit,
    handleClose
  } = props;

  return (
    <Popup open={open} onClose={handleClose}>
      <Popup.Title>Добро пожаловать!</Popup.Title>
      <Popup.Content>
        <div className={style.auth}>
          <div className={style.form}>
            <div className={style.services}>
              <div className={style.service}>
                <img src={images.email} width={48} height={48} alt="" />
              </div>
              <a href="/" className={style.service} title="Авторизоваться через Вконтакте">
                <img src={images.vk} width={48} height={48} alt="" />
              </a>
              <a href="/" className={style.service} title="Авторизоваться через Facebook">
                <img src={images.fb} width={48} height={48} alt="" />
              </a>
            </div>
            <Field
              mod={style.field}
              value={email}
              error={errors?.email}
              onChange={(value) => handleChange('email', value)}
            >
              <Field.Label>Email</Field.Label>
              <Field.Input type="email" />
            </Field>
            <Field
              mod={style.field}
              value={password}
              error={errors?.password}
              onChange={(value) => handleChange('password', value)}
            >
              <Field.Label>Пароль</Field.Label>
              <Field.Input type="password" />
            </Field>
            {
              errors?.error
              && <ErrorMessage>{errors.error}</ErrorMessage>
            }
            <Button mod={style.button} onClick={handleSubmit}>Войти</Button>
            <Link to="?popup=forgot-password" className={style.resetPassword}>Забыли пароль?</Link>
          </div>
          <div className={style.register}>
            <h3 className={style.title}>Еще не с нами?</h3>
            <Button customTag={Link} to={`?popup=${getEnums.popup.signUp}`}>Зарегистрироваться</Button>
          </div>
        </div>
      </Popup.Content>
    </Popup>
  );
}
