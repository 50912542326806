import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Tariff } from '@components';
import { dateFormat } from '@utils/helpers';
import { appConfig } from '@utils/config';

export function CurrentTariff({ tariff, subscription }) {
  const nowDate = moment();
  const dueDate = moment(subscription.due_date);
  const isDateAfterDue = nowDate.isAfter(dueDate);

  return (
    <Tariff theme="current">
      <Tariff.Title>{tariff.title}</Tariff.Title>
      <Tariff.Price>{tariff.price}</Tariff.Price>

      { (tariff.old_price > 0) && <Tariff.OldPrice>{tariff.old_price}</Tariff.OldPrice> }

      <Tariff.Descr>{tariff.description}</Tariff.Descr>

      <Tariff.Comment>

        {
          // Если включено автопродление подписки,
          // показываем дату следующего платежа
          subscription.is_recurrent
          && <>
            Следующее списание состоится <b>{dateFormat(subscription.due_date)}</b>
          </>
        }

        {
          // Если выключено автопродление подписки,
          // показываем дату окончания подписки
          !subscription.is_recurrent
          && <>
            Дата окончания подписки - <b>{dateFormat(subscription.due_date)}</b>
          </>
        }

        </Tariff.Comment>

      {
        // Показываем кнопку Продлить
        (
          // Если включено автопродление, подписка закончилась
          // и текущая дата после даты окончания подписки
          (!appConfig.subscribeActive.includes(subscription.status) && subscription.is_recurrent && isDateAfterDue)
          // Или если выключено автопродление
          || (!subscription.is_recurrent)
        )
        && <Button
          customTag={Link}
          to={`payment/${tariff.id}`}
          size="small"
          fullWidth={true}
        >
          Продлить
        </Button>
      }

    </Tariff>
  );
}
