import style from './Row.styl';
import cn from 'classnames';
import { Icon } from './Icon/Icon';
import { Avatar } from './Avatar/Avatar';
import { Title } from './Title/Title';
import { Birthday } from './Birthday/Birthday';
import { EditButton } from './EditButton/EditButton';
import { RowProvider } from './hooks';

export function Row({ type, children }) {
  return (
    <RowProvider value={{ type }}>
      <div className={cn(style.row, style[type])}>
        {children}
      </div>
    </RowProvider>
  );
}

Row.Icon = Icon;
Row.Avatar = Avatar;
Row.Title = Title;
Row.DateOfBirth = Birthday;
Row.EditButton = EditButton;
