import { api, handleAuthResponse } from '@utils/helpers';

/**
 * @typedef {Object} User
 */

/**
 * Авторизуемся на сайте
 * @param {String} email Email
 * @param {String} password Password
 * @returns {Promise<Boolean>}
 */
export async function authUser(email, password) {
  try {
    const response = await api.post('/auth/sign_in', { email, password });
    handleAuthResponse(response.data);
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      throw new Error('Пользователя с таким Email не существует или пароль не верный');
    }
  }
}

/**
 * @typedef {Object} Children
 */

/**
 * Регистрируемся на сайте
 * @param {String} email Email
 * @param {String} password Password
 * @param {String} password_confirmation Password confirmation
 * @param {Array<Children>} children Массив детей
 * @returns {Promise<Boolean>}
 */
export async function registerUser(email, password, password_confirmation, children) {
  try {
    const response = await api.post('/auth/sign_up', { email, password, password_confirmation , children_attributes: children});
    handleAuthResponse(response.data);
    return true;
  } catch(error) {
    if (error.response.status === 422) {
      const err = new Error('Server Error!');
      err.data = error.response.data.errors; // Передаем подробную информацию об ошибке
      throw err;
    }
  }
}

/**
 * Получаем данные о текущем пользователе
 * @returns {Promise<User>}
 */
export async function getUser() {
  try {
    const response = await api.get('/users/me');
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}

/**
 * Отменяем автопродление подписки
 * @returns {Promise<Object>}
 */
export async function postCancelRecurrentPayment() {
  try {
    const response = await api.post('/users/cancel_recurrent_payment');
    return response.data;
  } catch (error) {
    throw new Error('Server error!');
  }
}

/**
 * Сброс пароля
 * @param email
 * @returns {Promise<*>}
 */
export async function postResetPassword(email) {
  try {
    const response = await api.post('/auth/password', { email });
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      throw new Error('Пользователя с таким Email не существует или пароль не верный');
    }
  }
}

/**
 * Устанавливаем новый пароль
 * @param {String} reset_password_token Токен
 * @param {String} password Password
 * @param {String} password_confirmation Password confirmation
 * @returns {Promise<Boolean>}
 */
export async function postSetNewPassword(reset_password_token, password, password_confirmation) {
  try {
    const response = await api.patch('/auth/password', { reset_password_token, password, password_confirmation });
    handleAuthResponse(response.data);
    return true;
  } catch (error) {
    throw new Error('Код устарел!');
  }
}
