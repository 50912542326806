import { Title } from '@components';

export function PrivacyPolicy() {
  return (
    <>
      <Title type="h1" mod="page-title">Политика конфиденциальности</Title>
      <p><b>Устанавливая и используя в любом виде программы Чевостик вы принимаете нашу
        политику конфиденциальности. Если вы не согласны с этим, пожалуйста, не устанавливайте и не
        используйте программы</b></p>
      <p>Мы сохраняем за собой право вносить изменения и дополнения в нашу политику
        конфиденциальности в разумные промежутки времени, поэтому рекомендуем посещать эту страницу.
        Если Чевостик вносит существенные изменения в политику конфиденциальности, Чевостик сообщит
        вам с помощью уведомлений в наших программах. Продолжая использовать наши программы, вы тем
        самым соглашаетесь с политикой конфиденциальности.</p>
      <br />
      <h2>Политика конфиденциальности</h2>
      <p>Дата вступления в силу: 1 октября 2022</p>
      <p><b>1. Термины и определения</b></p>
      <ul>
        <li>
          Настоящая Онлайн Политика конфиденциальности (далее &ndash; &laquo;Политика&raquo;)
          представляет собой документ, разработанный с целью облегчения понимания Пользователями,
          какими способами Компания собирает, обрабатывает, использует, раскрывает и управляет
          Персональными Данными.
        </li>
        <li>
          Пользователи &ndash; это физические лица, пользующиеся любыми цифровыми продуктами или
          услугами, произведенными/оказываемыми Компанией с использованием Платформы
          (далее &ndash; &laquo;Пользователь&raquo; / &laquo;Пользователи&raquo; или &laquo;Вы&raquo;).
        </li>
        <li>
          Платформа представляет собой Интернет-платформу, на которой размещаются приложения, игры
          или любые другие цифровые продукты, производимые Компанией
          (далее &ndash; &laquo;Платформа&raquo;).
        </li>
        <li>
          Персональные данные &ndash; это информация, касающаяся идентифицируемого лица, которая
          может быть получена Компанией при использовании пользователем цифровых продуктов или
          услуг, производимых/предоставляемых Компанией (далее &ndash; &laquo;Персональные
          Данные&raquo;). Собранные и неидентифицируемые Персональные Данные &mdash; это информация,
          которая не может быть использована для идентификации Пользователя.
        </li>
        <li>
          Обработка Персональных Данных представляет собой действие, осуществляемое Контроллерами
          или Обработчиками (в соответствии с Общим регламентом ЕС по защите персональных данных
          (GDPR)), включая автоматические и неавтоматические операции с Персональными Данными
          Пользователей.
        </li>
        <li>
          Контролер данных &ndash; это организация, которая собирает Персональные Данные граждан
          Европейского Союза.
        </li>
        <li>
          Обработчик данных &ndash; это организация, которая обрабатывает Персональные Данные от
          лица Контролера Данных.
        </li>
        <li>
          Приложение &ndash; это программное обеспечение, разработанное Компанией и выполняющее
          определенную функцию непосредственно для Пользователя.
        </li>
        <li>
          Игра представляет собой игру, разработанную Компанией.
        </li>
      </ul>
      <p><b>2. Общие условия</b></p>
      <p>Наша Компания обязуется защищать и уважать вашу частную жизнь. Настоящая политика была
        разработана с целью информирования Вас о том, как мы собираем, обрабатываем, используем и
        управляем информацией, которую Вы предоставляете в связи с любой игрой или приложением или
        любыми другими цифровыми продуктами или услугами, производимыми/предоставляемыми Компанией
        (далее &ndash; &laquo;Продукты&raquo; или/и &laquo;Услуги&raquo;). Пожалуйста, обратите
        внимание, что сфера действия настоящей Политики ограничена только информацией, собранной
        Компанией при использовании продуктов. Настоящая Политика не распространяется на методы и
        технологии третьих лиц, которыми Компания не владеет и не контролирует.</p>
      <p>Мы обрабатываем ваши Персональные Данные только с вашего согласия. Любое использование
        Продуктов толкуется как ваше подтверждение и согласие с настоящей Политикой и нашими
        условиями использования, указанными ниже. Если вы не согласны с этими условиями, пожалуйста,
        не используйте Сервис.</p>
      <p>Что касается использования Персональных Данных наша Компания соблюдает положения и
        требования Федерального закона &laquo;О Персональных Данных&raquo; (№152-ФЗ), вступившим в
        силу с 27 июля 2006 года, и (там, где это применимо) РЕГЛАМЕНТ (ЕС) 2016/679 Европейского
        парламента и совета от 27 апреля 2016 года &laquo;О защите физических лиц в отношении
        обработки личных данных и о свободном движении таких данных, а также об отмене директивы
        95/46/ЕС (Общий регламент ЕС по защите персональных данных) (&ldquo;GDPR&rdquo;).</p>
      <p><b>3. Персональные Данные, собираемые нашими партнерами</b></p>
      <p>Обращаем внимание, что такие Персональные Данные автоматически собираются третьими лицами
        (контроллерами или обработчиками в соответствии с GDPR) в силу того, что Вы пользуетесь
        нашими Продуктами/Услугами:</p>
      <ul>
        <li>
          Данные о Ваших игровых успехах;
        </li>
        <li>
          Ваш IP-адрес и идентификаторы мобильных устройств (например, идентификатор устройства,
          рекламный идентификатор, MAC-адрес, IMEI);
        </li>
        <li>
          Данные на Вашем устройстве, такие как название устройства и операционная система, тип
          браузера и язык;
        </li>
        <li>
          Общие данные о местоположении;
        </li>
        <li>
          Точные данные геолокации (GPS только с вашего согласия);
        </li>
        <li>
          Информацию об использовании Вами нашего приложения или игры, такую как данные игрового
          процесса и ваши взаимодействия с другими игроками (если применимо).
        </li>
        <li>
          Наши программные продукты включают функции наших партнеров, такие как инструменты
          взаимодействия в социальных сетях и внутриигровая реклама. С перечнем этих партнеров можно
          ознакомиться ниже. Эти партнеры могут получать доступ к вашим данным и действовать в
          соответствии со своими собственными политиками конфиденциальности. Мы рекомендуем вам
          ознакомиться с их политикой конфиденциальности, чтобы узнать больше о способах обработки
          данных.
        </li>
      </ul>
      <p>Список третьих лиц, собирающих Персональные Данные в соответствии с настоящим пунктом,
        включает:</p>
      <ul>
        <li>
          Unity и Unity Analytics <a href="http://www.unity3d.com/">www.unity3d.com</a>, которые
          собирают информацию в соответствии со своей Политикой:&nbsp;
          <a href="https://unity3d.com/ru/legal/gdpr">https://unity3d.com/ru/legal/gdpr</a>&nbsp;и&nbsp;
          <a href="https://unity3d.com/ru/legal/privacy-policy">https://unity3d.com/ru/legal/privacy-policy</a>;
        </li>
        <li>
          Unityads <a
          href="https://unity3d.com/ru/unity/features/ads">https://unity3d.com/ru/unity/features/ads</a>,
          которые собирают информацию в соответствии со своей Политикой:&nbsp;
          <a href="https://unity3d.com/ru/legal/privacy-policy">https://unity3d.com/ru/legal/privacy-policy</a>;
        </li>
        <li>
          AdMob: <a href="https://www.admob.com/">https://www.admob.com</a>, информация о
          собираемых данных:&nbsp;
          <a href="https://support.google.com/admanager/answer/7670381">https://support.google.com/admanager/answer/7670381</a>;
        </li>
        <li>
          Google Firebase: <a href="http://firebase.google.com/">https://firebase.google.com</a>,
          которые собирают информацию в соответствии со своей Политикой:&nbsp;
          <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a>;
        </li>
      </ul>
      <p><b>4. Персональные Данные, которые мы можем получать из других источников и/или
        обмениваться с третьими лицами</b></p>
      <p>Мы также можем сотрудничать с третьими лицами (включая, например, партнеров, технических
        подрядчиков, платежные сервисы, поставщиков аналитических услуг, поставщиков поисковой
        информации), и можем получать от них Персональные Данные как часть услуг, которые мы
        предоставляем Вам, или получать такие данные по юридическим основаниям и обмениваться
        Персональными данными с такими третьими лицами. Такие третьи лица не будут собирать
        Персональные Данные, за исключением постоянных идентификаторов (таких как IDFA, IDFV,
        рекламный идентификатор или IP-адрес), которые могут однозначно идентифицировать Ваше
        устройство, но они не идентифицируют Вас лично.</p>
      <p>Принимая условия настоящей Политики, вы соглашаетесь с тем, что мы можем раскрывать любые
        собранные и неидентифицируемые Персональные Данные третьим лицам без каких-либо ограничений
        для любых целей.</p>
      <p><b>5. Для чего собираются и обрабатываются Ваши Персональные Данные</b></p>
      <p>Наша Компания собирает Персональные Данные по некоторым или всем из нижеследующим
        основаниям:</p>
      <ul>
        <li>
          Для предоставления Вам Продуктов и Услуг в соответствии с условиями, определенными в
          настоящем документе;
        </li>
        <li>
          По юридическим основаниям, например, если Вы заключили с нами договор;
        </li>
        <li>
          Для предоставления Вам информации о наших Продуктах и Услугах, если Вы дали свое
          согласие на их получение;
        </li>
        <li>
          Для рекламы и повышения игрового опыта с учетом индивидуальных требований Пользователей
          для обеспечения лучшего качества Продуктов или Услуг.
        </li>
      </ul>
      <p>Ваши Персональные данные могут обрабатываться потому, что:</p>
      <ul>
        <li>
          Мы заключили с вами договор;
        </li>
        <li>
          Вы дали нам разрешение на такую обработку;
        </li>
        <li>
          Мы обязаны оказывать Вам Услуги, после того, как Вы приобрели у нас какой-либо
          Продукт;
        </li>
        <li>
          Для соблюдения требований законодательства.
        </li>
      </ul>
      <p><b>6. Права и возможности</b></p>
      <p>В соответствии с GDPR вы имеете определенные права на изменение или удаление ваших
        Персональных Данных, запрос доступа к своим Персональным Данным и их исправление, получение
        ограничений на обработку ваших Персональных Данных, запрос копии своих Персональных Данных,
        возражение против обработки ваших Персональных Данных.</p>
      <p>Обратите внимание, что мы не собираем и не храним ваши Персональные Данные. В соответствии
        с пунктом 3 Политики ваши Персональные Данные собираются автоматически третьими лицами
        (контролерами или обработчиками согласно GDPR). Если Вы хотите запросить доступ к своим
        Персональным Данным, внести исправления в свои Персональные Данные, если Вы хотите удалить
        свои Персональные Данные, просто отправьте нам запрос по электронной почте на адрес: <a
          href="mailto:hello@chevostik.ru">hello@chevostik.ru</a>. Мы приложим все обоснованные
        усилия, чтобы помочь Вам, и направим ваш запрос нашим партнерам (контроллерам или
        обработчикам в соответствии с GDPR), которые могут получить доступ к вашим Персональным
        Данным, и которые работают в соответствии с их собственными политиками конфиденциальности.
        Мы ответим на все ваши запросы в разумные сроки.</p>
      <p>Мы заверяем Вас, что мы будем использовать ваши Персональные Данные в соответствии с
        настоящей Политикой в той мере, в какой это будет обоснованно необходимо для наших законных
        коммерческих целей, и будем использовать соответствующие средства защиты для обеспечения
        безопасности, целостности, точности и конфиденциальности информации, которую Вы предоставили
        нам.</p>
      <p>Если у Вас есть какие-либо вопросы, комментарии или сомнения по поводу настоящей Политики,
        пожалуйста, свяжитесь с нашей Компанией по электронной почте: <a
          href="mailto:hello@chevostik.ru">hello@chevostik.ru</a></p>
      <p><b>7. Отказ от целевой рекламы</b></p>
      <p>Некоторые из наших игр имеют возможность персонализировать вашу рекламу, используя наборы
        средств разработки программного обеспечения (СРПО) Admob, Flurry, UnityAds и других
        сторонних компаний, в частности, эти СРПО могут собирать и обрабатывать ваши Персональные
        Данные, такие как идентификатор устройства, данные о местоположении, демографические и
        другие данные для предоставления Вам соответствующей рекламы. Согласившись на это, Вы также
        увидите объявления, которые Admob и ее партнеры считают наиболее актуальными для Вас.</p>
      <p>Вы можете отказаться от персонализированной рекламы на основе интересов в мобильных
        приложениях, проверив настройки конфиденциальности на своем устройстве с системой Android
        или iOS, и выбрав &ldquo;ограничить отслеживание рекламы&rdquo; (Apple iOS)
        или &ldquo;отказаться от рекламы на основе интересов&rdquo; (Android). Для того, чтобы
        отказаться от персонализированных предложений во время игры, Вы можете использовать
        параметры в настройках игры.</p>
      <p>В то же время некоторые наши игры могут использовать наборы средств разработки программного
        обеспечения (СРПО) Flurry, Unity Analytics и других сторонних компаний для анализа игрового
        опыта игроков и вашего игрового опыта с целью его повышения. Такие СРПО могут собирать и
        обрабатывать ваши Персональные Данные, такие как идентификаторы устройств, данные о
        местоположении, демографические и другие данные в анонимной форме, чтобы предоставить Вам
        соответствующий игровой опыт для улучшения качества игры.</p>
      <p>Если Вы когда-нибудь передумаете, Вы можете отозвать свое согласие в
        меню &laquo;Настройки&raquo; на устройстве Android или iOS.</p>
      <p><b>8. Несовершеннолетние пользователи</b></p>
      <p>Поскольку мы не обрабатываем и не используем информацию о возрасте Пользователей наших
        Продуктов, Компания может быть не в состоянии предотвратить обработку данных лиц, не
        достигших совершеннолетия. Мы также не собираем информацию о возрасте через наши Продукты.
        Если Вы являетесь родителем или опекуном ребенка и считаете, что мы непреднамеренно получили
        личную информацию о ребенке, свяжитесь с нами, и мы удалим эту информацию из наших записей.
        Если вы являетесь родителем подростка младше 16 лет (или любого соответствующего возраста в
        вашей стране), мы запросим ваше согласие, если вы хотите, чтобы ваши несовершеннолетние дети
        получили доступ к Продуктам.</p>
      <p>Мы также придерживаемся принципов Закона Соединенных Штатов Америки о защите
        конфиденциальной информации детей в интернете (&laquo;COPPA&raquo;), призванного защитить
        конфиденциальную информацию о детях в интернете. Если Вы хотите узнать больше о COPPA, вы
        можете обратиться к разделу &laquo;Конфиденциальность детей&raquo; интернет сайта
        Федеральной торговой комиссии Соединенных Штатов Америки.</p>
      <p>Мы не собираем намеренно личную информацию от детей младше 13 лет, и если Пользователь сам
        укажет, что является ребенком младше 13 лет, в обращении за поддержкой или в другой форме
        обратной связи, мы не будем брать, хранить или использовать личную информацию такого
        Пользователя, и примем меры для ее надежного удаления.</p>
      <p>Если Вы считаете, что мы можем непреднамеренно собирать конфиденциальную информацию о Вашем
        ребенке без Вашего предварительного согласия, Вы можете связаться с нами по электронной
        почте: <a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a></p>
      <p>Мы обращаем Ваше внимание, что мы не собираем никакой личной информации. Когда вы
        скачиваете наши Приложения и затем пользуетесь ими, мы не требуем предоставить какую-либо
        информацию и не фиксируем никакие данные о вас или вашем Приложении. Мы используем только
        общую информацию о Вас, чтобы проанализировать, как пользователи взаимодействуют с нашими
        Приложениями. Она включает в себя только технические характеристики Вашего устройства. Мы
        используем IP Вашего устройства с единственной целью обеспечения поддержки внутренних
        операций нашего онлайн Сервиса, для поддержания и анализа его эффективности.</p>
      <p>Родители, которые зарегистрировали своего ребенка в наших Играх и Приложениях, должны
        понимать, что информация, включая, но не ограничиваясь, имя ребенка, фамилию, страну
        проживания и поведенческую деятельность, может быть собрана третьими лицами, включая,
        например, аналитику провайдеров, поставщиков поисковой информации.</p>
      <p>Наши Игры и Приложения могут содержать рекламные объявления о сторонних продуктах и
        услугах, но в любом случае мы не разрешали в наших Играх и Приложениях показ рекламы для
        детей, в отношении которых мы достоверно знаем, что Пользователю не исполнилось 13 лет.</p>
      <p><b>9. Изменения Политики</b></p>
      <p>Компания может периодически вносить изменения в настоящую Политику. Любые такие изменения
        будут опубликованы здесь, в настоящей Политике, и мы рекомендуем Вам регулярно проверять
        наличие обновлений или изменений. Мы оставляем за собой право вносить изменения в настоящую
        Политику в любое время. Пожалуйста, ознакомьтесь с Политикой каждый раз, когда Вы
        пользуетесь нашими Платформами, для того чтобы быть в курсе любых изменений в нашей Политике
        Конфиденциальности. В нашей Политике будут указаны данные последнего обновления. Ваше
        дальнейшее использование наших Платформ подтвердит Ваше согласие с изменениями в нашей
        Политике.</p>
      <p><b>10. Контактная информация</b></p>
      <p>ИП Томисонец Дмитрий Алексеевич<br/>www:&nbsp;
        <a href="http://www.chevostik.ru/">www.chevostik.ru</a>
        <br/>
        Адрес электронной почты:&nbsp;
        <a href="mailto:hello@chevostik.ru">hello@chevostik.ru</a></p>
      <p>Если Вы свяжетесь с нами по электронной почте, мы воспользуемся вашим именем и адресом
        электронной почты.</p>
    </>
  );
}
