import React, { createContext } from 'react';

const ScrollContext = createContext({
    scrollingElement: null,
});

function ScrollProvider({ children, value }) {
    return (
      <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
    );
}

function useScrollContext() {
    const context = React.useContext(ScrollContext);

    if (context === undefined) {
        throw new Error('useScrollContext must be used within a ScrollProvider');
    }

    return context;
}

export { ScrollProvider, useScrollContext };
