import style from './Lessons.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { getEnums } from '@utils/config';
import { devicesEnums, useDevice } from '@utils/hooks';
import * as images from './images';
import {memo} from "react";
import {lessonsMobile, lessonsMobileRetina} from "./images";
import {Link} from "react-router-dom";
import {useAuth} from "../../../../utils/hooks";
import {Button} from "../../../../components";

function contentInterpolator(proportion) {
  if (!proportion) {
    return;
  }

  //const rate = 6;

/*  if (proportion <= 0) {
    return { transform: 'translateY(0)' };
  } else if (proportion > 0 && proportion <= 1) {
    return { transform: `translateY(${-proportion * rate * 100}%)` };
  } else if (proportion > 1) {
    return { transform: `translateY(${-rate * 100}%)` };
  }*/
}

const LessonContent = memo((props) => {
  const { transformationStyle = {}, isMobile = false } = props;
  const device = useDevice();
  const isLoginIn = useAuth();
  return (
      
    <div className={style.wrap}>

      <div className={style.content} style={transformationStyle}>
        <div>
          <img className={style.che} src={images.che} srcSet={`${images.cheRetina} 2x`} alt=""/>
        </div>
        <div>
        <Title type="h2" mod={style.title}>
          Десятки<br/>
          интерактивных<br/>
          уроков
        </Title>
        <div className={style.descr}>
          Огромный охват тем -<br/>
          от древнего мира до полёта на Марс,<br/>
          от насекомых до динозавров,<br/>
          от физики до истории Эрмитажа.

        </div>
          {
              !isLoginIn
              && <Button
                  customTag={Link}
                  to={`?popup=${getEnums.popup.signUp}&theme=green`}
                  mod={style.button}
                  color="green"
                  size={'small'}
              >
                Попробовать сейчас
              </Button>
          }
        </div>

      </div>
    </div>
  )
});

export function Lessons() {
  const device = useDevice();

  if (device === devicesEnums.tablet) {
    return (
      <Section fullHeight={false}>
        <section className={style.lessons}>
          <LessonContent isMobile />
        </section>
      </Section>
    );
  }

  return (
    <StickyView>
      {(proportion) => (
        <Section>
          <section className={style.lessons}>
            <LessonContent transformationStyle={contentInterpolator(proportion)} />
            {/*<div className={style.images}>
              {device === devicesEnums.pc
                && <>
                  <img
                    className={style.col}
                    src={images.col1}
                    srcSet={`${images.colRetina1} 2x`}
                    alt=""
                   // style={fromBottomInterpolator(proportion, 1.35)}
                  />
                  <img
                    className={style.col}
                    src={images.col2}
                    srcSet={`${images.colRetina2} 2x`}
                    alt=""
                    //style={fromBottomInterpolator(proportion, 1.2)}
                  />
                  <img
                    className={style.col}
                    src={images.col3}
                    srcSet={`${images.colRetina3} 2x`}
                    alt=""
                   // style={fromBottomInterpolator(proportion, 1.05)}
                  />
                  <img
                    className={style.col}
                    src={images.col4}
                    srcSet={`${images.colRetina4} 2x`}
                    alt=""
                  //  style={fromBottomInterpolator(proportion, 1.25)}
                  />
                  <img
                    className={style.col}
                    src={images.col5}
                    srcSet={`${images.colRetina5} 2x`}
                    alt=""
                   // style={fromBottomInterpolator(proportion, 1)}
                  />
                  <img
                    className={style.col}
                    src={images.col6}
                    srcSet={`${images.colRetina6} 2x`}
                    alt=""
                    //style={fromBottomInterpolator(proportion, 1.4)}
                  />
                  <img
                    className={style.col}
                    src={images.col7}
                    srcSet={`${images.colRetina7} 2x`}
                    alt=""
                 //   style={fromBottomInterpolator(proportion, 1.2)}
                  />
                  <img
                    className={style.col}
                    src={images.col8}
                    srcSet={`${images.colRetina8} 2x`}
                    alt=""
              //      style={fromBottomInterpolator(proportion, 1.45)}
                  />
                  <img
                    className={style.col}
                    src={images.col9}
                    srcSet={`${images.colRetina9} 2x`}
                    alt=""
                   // style={fromBottomInterpolator(proportion, 1.05)}
                  />
                  <img
                    className={style.col}
                    src={images.col10}
                    srcSet={`${images.colRetina10} 2x`}
                    alt=""
                 //   style={fromBottomInterpolator(proportion, 1.3)}
                  />
                </>}
            </div>*/}
          </section>
        </Section>
      )}
    </StickyView>
  );
}
