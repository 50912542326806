import { Link, useLocation } from 'react-router-dom';
import {useCallback, useEffect, useRef} from 'react';
import cn from 'classnames';
import style from './Universal.styl';
import { Title, Button } from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator, fromBottomInterpolator } from '@utils/helpers';
import { devicesEnums, useAuth, useDevice } from '@utils/hooks';
import { appConfig, getEnums } from '@utils/config';
import * as images from './images';

export function Universal() {
  const device = useDevice();
  const isLoginIn = useAuth();
  const { hash } = useLocation();
  const ref = useRef(null);

  function circleInterpolator(proportion, direction = 1) {
   /* if (proportion <= -1) {
      return { transform: `translateX(${direction * 50}%)` };
    } else if (proportion > -1 && proportion <= 1) {
      return { transform: `translateX(${direction * (50 - (1 + proportion) * 0.25 * 100)}%)` };
    } else if (proportion > 1) {
      return { transform: 'translateX(0)' };
    }*/
  }

  function circlesWrapInterpolatorGenerator(proportion) {
    if (device === devicesEnums.pc) {
      return (rate) => fromBottomInterpolator(proportion, rate);
    }

    return () => null;
  }

  function circleInterpolatorGenerator(proportion) {
    if (device === devicesEnums.pc) {
      return (rate, direction) => circleInterpolator(proportion, direction);
    }

    return (rate) => toTopInterpolator(proportion, rate);
  }

  const scrollToBlock = useCallback(() => {
    if (!hash || hash !== '#universal') {
      return false;
    }

    if (ref.current) {
      const scrolled = document.getElementById('scroll-home');
      const sticky = ref.current.parentElement;
      const wrapper = sticky.parentElement;
      let scrollTop = wrapper.offsetTop;
    //  let scrollTop = wrapper.offsetTop + document.documentElement.clientHeight;
      scrolled.scrollTo({top: scrollTop, behavior: 'smooth'});
    }
  }, [hash]);

  useEffect(() => {
    window.addEventListener('load', scrollToBlock);
    return () => window.removeEventListener('load', scrollToBlock);
  }, [scrollToBlock]);

  return (
    <StickyView >
      {(proportion) => (
        <Section ref={ref}>
          <div className={style.universal}>
            <Title type="h2" mod={style.title}>Занимайтесь, где удобно</Title>
            <div className={style.subtitle}>
              Начинайте на одном устройстве<br/>
              и продолжайте на другом - ваши успехи сохраняются.
            </div>
            <div className={style.circles} style={circlesWrapInterpolatorGenerator(proportion)(0.2)}>
              <div className={cn(style.circle, style.pc)} style={circleInterpolatorGenerator(proportion)(0.2)}>
                <img src={images.chePc} srcSet={`${images.chePcRetina} 2x`} alt="" className={cn(style.che, style.chePc)}/>
                <h3 className={style.sectionTitle}>На компьютере</h3>
                <div className={style.descr}>В любом браузере.</div>

                {
                  isLoginIn
                  && <Button
                    customTag="a"
                    href={appConfig.gameHost}
                    mod={style.button}
                    size="small"
                  >
                    Играть
                  </Button>
                }

                {
                  !isLoginIn
                  && <Button
                    mod={style.button}
                    size="small"
                    customTag={Link}
                    to={`?popup=${getEnums.popup.signUp}`}
                  >
                    Начать сейчас
                  </Button>
                }

              </div>
              <div className={cn(style.circle, style.mobile)} style={circleInterpolatorGenerator(proportion)(0.4, -1)}>
                <img src={images.cheMobile} srcSet={`${images.cheMobileRetina} 2x`} alt="" className={cn(style.che, style.cheMobile)}/>
                <h3 className={style.sectionTitle}>На планшете или телефоне</h3>
                <div className={style.descr}>Установите приложение.</div>
                <div className={style.markets}>
                  <a href={appConfig.appstoreLink} className={style.link} target="_blank" rel="noopener noreferrer">
                    <img src={images.appstore} width={154} height={48} alt="" className={style.image}/>
                  </a>
                  <a href={appConfig.playMarketLink} className={style.link} target="_blank" rel="noopener noreferrer">
                    <img src={images.playmarket} width={154} height={48} alt="" className={style.image}/>
                  </a>
                  <a href={appConfig.rustoreLink} className={style.link} target="_blank" rel="noopener noreferrer">
                    <img src={images.rustore} width={154} height={48} alt="" className={style.image}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
