import cn from 'classnames';
import style from './Title.styl';
import { useBannerContext } from '../hooks';

export function Title({ children }) {
  const { theme } = useBannerContext();

  return (
    <h3 className={cn(style.title, style[theme || 'default'])}>
      {children}
    </h3>
  );
}
