import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Register } from '@components';
import { signUp } from '@store/usersSlice';
import { isEmail } from '@utils/helpers';
import {appConfig} from "../../utils/config";

export function RegisterContainer({ open, theme }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function closePopup() {
    navigate("/");
  }

  // Получаем данные из формы
  function setFieldsValues(field, value) {
    switch(field) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'passwordConfirmation':
        setPasswordConfirmation(value);
        break;
      default:
        break;
    }
  }

  // Отправляем данные на сервер
  async function submit() {
    // Проверяем поля на ошибки
    let emailError;
    let passwordError;
    let passwordConfirmationError;

    if (!isEmail(email)) {
      emailError = 'Введите корректный Email';
    }

    if (email.trim().length === 0) {
      emailError = 'Введите Email';
    }

    if (password.trim().length === 0) {
      passwordError = 'Введите пароль';
    }

    if (passwordConfirmation.trim() !== password.trim()) {
      passwordError = 'Пароли не совпадают';
      passwordConfirmationError = 'Пароли не совпадают';
    }

    setErrors({
      email: emailError,
      password: passwordError,
      passwordConfirmation: passwordConfirmationError
    });

    if (emailError || passwordError || passwordConfirmationError) {
      return false;
    }

    // Без ребенка регистрация не пройдет
    const children = [{ name: 'Ребенок' }];

    try {
      await dispatch(signUp({ email, password, passwordConfirmation, children })).unwrap();

      if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        // eslint-disable-next-line no-undef
     //   VK.Goal('complete_registration');

        // eslint-disable-next-line no-undef
        ym(92335881,'reachGoal','registration')

        // eslint-disable-next-line no-undef
     //   gtag('event', 'registration', { 'event_category': 'lead', 'event_label': 'registration' });

        // eslint-disable-next-line no-undef
      //  _tmr.push({ type: 'reachGoal', id: 3319847, goal: 'Registration'});
      }

      window.location.replace(appConfig.gameHost);
    } catch(error) {
      let emailError;
      let passwordError;

      if (error.email) {
        emailError = `Email ${error.email}`;
      }

      if (error.password) {
        passwordError = `Пароль ${error.password}`;
      }

      setErrors({ email: emailError, password: passwordError });
    }
  }

  return (
    <Register
      open={open}
      theme={theme}
      email={email}
      password={password}
      passwordConfirmation={passwordConfirmation}
      errors={errors}
      handleChange={setFieldsValues}
      handleSubmit={submit}
      handleClose={closePopup}
    />
  )
}
