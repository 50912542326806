import axios from "axios";

/**
 * @typedef {Object} Tariff
 */

/**
 * Получаем список тарифов
 * @returns {Promise<[Tariff]>}
 */
export async function updatePassword(pass) {
    try {
        const response = await axios.patch(`https://game.chevostik.ru/api/v1/users/update_password`,pass);
        return response.data;
    } catch (error) {
        throw new Error('Server Error!');
    }
}
