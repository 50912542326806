import axios from 'axios';
import { appConfig } from '@utils/config';
import * as auth from './auth';

const apiURL = {
  v1: `${appConfig.apiHost}/api/v1`,
  v2: `${appConfig.apiHost}/api/v2`,
  v1Game: `${appConfig.gameHost}/api/v1`
};

function apiConfig(version) {
  const api = axios.create({
    headers: { 'Content-Type': 'application/json' },
    timeout: 60000,
    transformRequest: [
      (data) => JSON.stringify(data)
    ],
    validateStatus: function(status) {
      if (status === 404) {
        return false;
      }

      if (status === 401) {
        auth.logout();
        return false;
      }

      return (status >= 200 && status < 300);
    }
  });

  api.interceptors.request.use(
    (config) => {
      let url = config.url;

      if (auth.getToken()) {
        config.headers.Authorization = `Bearer ${auth.getToken()}`;
      }

      url = apiURL[version] + url;
      config.url = url;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
}

/**
 * Config v1 api
 */
export const api = apiConfig('v1');

/**
 * Config v2 api
 */
export const apiV2 = apiConfig('v2');
