import style from './Section.styl';
import cn from 'classnames';
import {forwardRef} from "react";

export const Section = forwardRef(({ id, name, customTag, fullHeight = true, mod, children }, ref) => {
  let Tag = customTag;

  if (!customTag) {
    Tag = 'section';
  }

  return (
    <Tag ref={ref} id={id} name={name} className={cn(style.section, { [style.fullHeight]: fullHeight }, mod)}>
      {children}
    </Tag>
  );
});
