import { Popup, Youkassa } from '@components';

export function Payment({ token, backUrl, handleClose }) {
    window.ym(92335881,'reachGoal','paymentstart');
  return (
    <Popup size="small" onClose={handleClose}>
      <Popup.Title>Выберите способ оплаты</Popup.Title>
      <Popup.Content id="widget-youkassa">
        <Youkassa
          token={token}
          parentId="widget-youkassa"
          backUrl={backUrl}
        />
      </Popup.Content>
    </Popup>
  );
}
