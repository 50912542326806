import React from 'react';

const FieldContext = React.createContext(undefined);

function FieldProvider({ children, value }) {
  return (
    <FieldContext.Provider value={value}>{children}</FieldContext.Provider>
  );
}

function useFieldContext() {
  const context = React.useContext(FieldContext);

  if (context === undefined) {
    throw new Error('useFieldContext must be used within a FieldProvider');
  }

  return context;
}

export { FieldProvider, useFieldContext };
