import { configureStore } from '@reduxjs/toolkit';
import childrenReducer from './childrenSlice';
import usersReducer from './usersSlice';
import tariffsReducer from './tariffsSlice';
import paymentReducer from './paymentSlice';

export default configureStore({
  reducer: {
    children: childrenReducer,
    users: usersReducer,
    tariffs: tariffsReducer,
    payment: paymentReducer
  }
});
