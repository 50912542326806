import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  authUser,
  getUser,
  postCancelRecurrentPayment,
  postResetPassword,
  postSetNewPassword,
  registerUser
} from '@api/users';

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async function(_, { rejectWithValue }) {
    try {
      return await getUser();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const cancelRecurrentPayment = createAsyncThunk(
  'users/cancelRecurrentPayment',
  async function(_, { rejectWithValue }) {
    try {
      return await postCancelRecurrentPayment();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const signIn = createAsyncThunk(
  'users/signIn',
  async function({ email, password }, { rejectWithValue, dispatch}) {
    try {
      const data = await authUser(email, password);
      await dispatch(fetchUser());
      return data;
    } catch(error) {
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  'user/signUp',
  async function({ email, password, passwordConfirmation, children }, { rejectWithValue, dispatch}) {
    try {
      const data = await registerUser(email, password, passwordConfirmation, children);
      await dispatch(fetchUser());
      return data;
    } catch(error) {
      return rejectWithValue(error.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async function({ email }, { rejectWithValue }) {
    try {
      return await postResetPassword(email);
    } catch(error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setNewPassword = createAsyncThunk(
  'user/setNewPassword',
  async function({ password, passwordConfirmation }, { rejectWithValue, dispatch , getState }) {
    try {
      const resetPasswordToken = getState().users.resetPasswordToken;
      const data = await postSetNewPassword(resetPasswordToken, password, passwordConfirmation);
      await dispatch(fetchUser());
      await dispatch(cleanResetToken());
      return data;
    } catch(error) {
      return rejectWithValue(error.message);
    }
  }
);

export const selectUser = (state) => state.users.user;
export const selectSubscription = (state) => state.users.user?.subscription;

const setError = (state, action) => {
  state.status = 'rejected';
  state.error = action.payload;
};

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: null,
    status: null,
    resetPasswordToken: null,
    error: null
  },
  reducers: {
    cleanUser: (state) => {
      state.user = null;
    },
    setResetToken: (state, action) => {
      state.resetPasswordToken = action.payload.resetPasswordToken;
    },
    cleanResetToken: (state) => {
      state.resetPasswordToken = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(fetchUser.rejected, setError)
      .addCase(cancelRecurrentPayment.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(cancelRecurrentPayment.fulfilled, (state) => {
        state.user.subscription.is_recurrent = false;
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(cancelRecurrentPayment.rejected, setError)
      .addCase(signIn.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state) => {
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(signIn.rejected, setError)
      .addCase(signUp.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state) => {
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(signUp.rejected, setError)
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(resetPassword.rejected, setError)
      .addCase(setNewPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(setNewPassword.fulfilled, (state) => {
        state.status = 'resolved';
        state.error = null;
      })
      .addCase(setNewPassword.rejected, setError)
  }
});

export const { cleanUser, setResetToken, cleanResetToken } = usersSlice.actions;

export default usersSlice.reducer;
